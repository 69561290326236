import CloseIcon from '@mui/icons-material/Close'
import { Chip } from '@mui/material'
import clsx from 'clsx'
import { FC, ReactElement } from 'react'

import styles from './Chip.module.scss'

interface IProps {
  type?: 'success' | 'warning' | 'error' | 'default'
  icon?: ReactElement
  label: string
  onClick?: () => void
  onDelete?: (index?: number) => void
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const CustomChip: FC<IProps> = (props) => {
  const { type, size = 'md', icon, label, onClick, onDelete, className } = props

  const colorClassName = {
    success: styles.colorSuccess,
    warning: styles.colorWarning,
    error: styles.colorError,
    default: styles.colorDefault
  }

  return (
    <Chip
      clickable={!!onClick}
      icon={icon}
      color={type}
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<CloseIcon className={styles.deleteIcon} />}
      classes={{
        root: clsx(
          styles.chip,
          styles[size],
          type && colorClassName[type],
          className
        ),
        label: styles.label
      }}
    />
  )
}

export default CustomChip
