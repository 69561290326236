import AddIcon from '@mui/icons-material/Add'
import clsx from 'clsx'
import { isEqual } from 'lodash'
import React, { FC, UIEvent, useEffect, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Button, Col, Row, Text } from '@/components/atoms'
import { TitleWithDescription } from '@/components/molecules'
import { BREAKPOINTS } from '@/constants'
import {
  CheckInModal,
  CheckOutModal,
  DeleteQueueItemModal,
  QueueCard
} from '@/features/gate'
import { useFetchQueueEventsQuery } from '@/features/gate/api'
import { LaneDirection } from '@/features/gate/enums'
import { GateQueueEvent, IQueueModalType, Lane } from '@/features/gate/types'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './GateQueue.module.scss'

interface IProps {
  type: LaneDirection
  lane: Lane
  gateId: string
  onDelete: (id: string) => void
  onSwap: (type: LaneDirection, id: string) => void
}

const GateQueue: FC<IProps> = (props) => {
  const { type, lane, gateId, onDelete, onSwap } = props

  const { org } = useStore((store) => store.user)

  const [selectedItem, setSelectedItem] = useState<GateQueueEvent | undefined>()
  const [showBorder, setShowBorder] = useState(false)

  const { visible, openModal, closeModal } = useModal<IQueueModalType>()
  const { width } = useWindowSize()
  const { organization_id = '' } = org || {}

  const { data, refetch } = useFetchQueueEventsQuery(
    {
      org_id: organization_id,
      lane_id: lane.id,
      direction: lane.direction
    },
    { skip: !organization_id, pollingInterval: 5000 }
  )

  const items = data?.data || []

  const title = type === LaneDirection.Arriving ? 'Check-In' : 'Check-Out'

  const emptyQueueDescription = `Power units ready to be checked-${
    type === LaneDirection.Arriving ? 'in' : 'out'
  } will appear here.`

  const tabsMode = width <= BREAKPOINTS.LG

  const openModalCustom = (
    item: GateQueueEvent | undefined,
    modalType: IQueueModalType
  ) => {
    setSelectedItem(item)
    openModal(modalType)
  }

  const closeModalCustom = () => {
    closeModal()
    setSelectedItem(undefined)

    refetch()
  }

  const onManualCheckIn = () => {
    openModalCustom(undefined, type)
  }

  const handleDelete = () => {
    if (!selectedItem) return

    onDelete(selectedItem.id)
    closeModal()
  }

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    const { scrollTop } = e.target as HTMLElement

    const scrolled = scrollTop > 0

    if (scrolled !== showBorder) {
      setShowBorder(scrolled)
    }
  }

  useEffect(() => {
    if (selectedItem && items.length) {
      const item = items.find((i) => i.id === selectedItem.id)

      if (item?.id && !isEqual(selectedItem, item)) {
        setSelectedItem(item)
      }
    }
  }, [selectedItem, items])

  return (
    <div
      className={clsx(styles.queueWrapper, tabsMode && styles.small)}
      onScroll={handleScroll}
    >
      {visible === LaneDirection.Arriving && (
        <CheckInModal
          lane={lane}
          gateId={gateId}
          closeModal={closeModalCustom}
          item={selectedItem}
        />
      )}

      {visible === LaneDirection.Departing && (
        <CheckOutModal
          lane={lane}
          gateId={gateId}
          closeModal={closeModalCustom}
          item={selectedItem}
        />
      )}

      {visible === 'delete' && (
        <DeleteQueueItemModal
          closeModal={closeModalCustom}
          onDelete={handleDelete}
        />
      )}

      {!tabsMode && (
        <Row
          items="center"
          gap={20}
          justify="between"
          className={clsx(styles.queueHeader, showBorder && styles.border)}
        >
          <Row items="center" gap={10}>
            <Text
              type={TextTypes.TEXT_MD}
              color={Color.gray700}
              weight={FontWeight.SEMIBOLD}
            >
              {title}
            </Text>

            {!!items.length && (
              <div className={styles.queueLength}>
                <Text
                  type={TextTypes.TEXT_MD}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.gray700}
                >
                  {items.length}
                </Text>
              </div>
            )}
          </Row>

          <Button type="outlined" onClick={onManualCheckIn}>
            <AddIcon />
          </Button>
        </Row>
      )}

      {items.length ? (
        <Col gap={8} items="stretch" className={styles.queueItems}>
          {items.map((item) => (
            <QueueCard
              key={item.id}
              type={type}
              item={item}
              onSwap={onSwap}
              openModal={openModalCustom}
            />
          ))}
        </Col>
      ) : (
        <TitleWithDescription
          title="There is nothing in the queue."
          description={emptyQueueDescription}
        />
      )}
    </div>
  )
}

export default GateQueue
