import * as yup from 'yup'

const CargoAssetDetailsSchema = yup.object({
  date: yup.object(),
  appointmentType: yup.string().required('Appointment Type is required'),

  assetType: yup.string().required('Asset type is required'),
  assetName: yup
    .string()
    .max(50, 'Max length is 50')
    .required('ID is required'),
  assetCarrier: yup.string().required('Carrier is required'),
  chassisName: yup.string().max(50, 'Max length is 50'),
  assetLPN: yup.string().trim().max(20, 'Max length is 20'),
  assetLPNState: yup.string(),
  arrivingLoadStatus: yup.string(),
  shipmentNumber: yup.string().max(50, 'Max length is 50'),
  account: yup.array(),
  sealNumber: yup.string().max(50, 'Max length is 50'),
  sealMatchPW: yup.boolean().notRequired()
})

export default CargoAssetDetailsSchema
