import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined'
import { Collapse } from '@mui/material'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, useState } from 'react'

import { Button, Col, Row } from '@/components/atoms'
import { ToggleDetailsButton } from '@/components/molecules'
import { CardRow } from '@/features/gate'
import { useGetPresignedUlQuery } from '@/features/gate/api'
import { LaneDirection } from '@/features/gate/enums'
import { GateQueueEvent, IQueueModalType } from '@/features/gate/types'
import { weightClassesFriendlyNames } from '@/utils/mappers'

import styles from './QueueCard.module.scss'

interface IProps {
  item: GateQueueEvent
  type: LaneDirection
  onSwap: (type: LaneDirection, id: string) => void
  openModal: (item: GateQueueEvent, type: IQueueModalType) => void
}

const QueueCard: FC<IProps> = (props) => {
  const { item, type, onSwap, openModal } = props
  const { metadata, created_date, id, organization_id, site_id } = item

  const {
    power_unit_weight_class,
    power_unit_license_plate_number,
    power_unit_owner_id,
    power_unit_carrier_name,
    cargo_asset_owner_id,
    cargo_asset_license_plate_number,
    chassis_id,
    snapshot_url
  } = metadata

  const [expanded, setExpanded] = useState(false)
  const [animationInProgress, setAnimationInProgress] = useState(false)

  const isCheckIn = type === LaneDirection.Arriving

  const { data: imageUrl } = useGetPresignedUlQuery({
    org_id: organization_id,
    site_id,
    resource_id: snapshot_url
  })

  const toggleDetails = () => {
    setExpanded(!expanded)

    if (expanded) {
      setAnimationInProgress(true)
    }
  }

  const ToggleButton = (
    <ToggleDetailsButton
      showDetails={expanded}
      className="!tw-px-0 tw-w-fit"
      onClick={toggleDetails}
    />
  )

  const Actions = (
    <Row items="stretch" justify="between" gap={16}>
      <Button
        type="secondary"
        startIcon={isCheckIn ? <LoginOutlinedIcon /> : <LogoutOutlinedIcon />}
        onClick={() => openModal(item, type)}
      >
        {isCheckIn ? 'Check-In' : 'Check-Out'}
      </Button>

      <Row items="stretch" gap={8}>
        <Button
          type="outlined"
          className={styles.swap}
          onClick={() => onSwap(type, id)}
        >
          <SwapVertOutlinedIcon />
        </Button>

        <Button
          type="outlined"
          className={styles.bin}
          onClick={() => openModal(item, 'delete')}
        >
          <DeleteOutlineOutlinedIcon />
        </Button>
      </Row>
    </Row>
  )

  return (
    <div className={styles.card}>
      <img src={imageUrl} alt="Truck Img" className={styles.truckImg} />

      <Col items="stretch" className={styles.cardBody}>
        <CardRow
          secondBold
          titleOne="Date"
          titleTwo="Time"
          valueOne={dayjs(created_date).format('MM/DD/YYYY')}
          valueTwo={dayjs(created_date).format('hh:mm A')}
        />

        <CardRow
          titleOne="Power Unit LPN"
          titleTwo="Power Unit ID"
          valueOne={power_unit_license_plate_number?.[0]}
          valueTwo={power_unit_owner_id?.[0]}
        />

        <Collapse
          in={expanded}
          unmountOnExit
          onExited={() => setAnimationInProgress(false)}
          className={clsx(animationInProgress && '!tw-m-0')}
        >
          <CardRow
            titleOne="Class"
            titleTwo="Operating Carrier"
            valueOne={
              power_unit_weight_class
                ? weightClassesFriendlyNames[power_unit_weight_class]
                : undefined
            }
            valueTwo={power_unit_carrier_name}
          />
        </Collapse>

        <CardRow
          titleOne="Cargo Asset ID"
          titleTwo={expanded ? 'Cargo Asset LPN' : undefined}
          valueOne={cargo_asset_owner_id?.[0]}
          valueTwo={
            expanded ? cargo_asset_license_plate_number?.[0] : undefined
          }
          button={ToggleButton}
        />

        <Collapse
          in={expanded}
          unmountOnExit
          className={clsx(animationInProgress && '!tw-m-0 tw-opacity-0')}
        >
          <CardRow
            titleOne="Chassis ID"
            titleTwo={undefined}
            valueOne={chassis_id}
            valueTwo={undefined}
            button={ToggleButton}
          />
        </Collapse>

        {Actions}
      </Col>
    </div>
  )
}

export default QueueCard
