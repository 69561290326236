import { IUserPortalsListItem } from '@/features/auth/types'
import { INavigationSubItem } from '@/types/interfaces/ui'
import { navigationItems } from '@/utils/data/navigationItems'

const getAvailableLinksPerPortal = (
  selectedPortal: IUserPortalsListItem | null | undefined
) =>
  selectedPortal?.id && selectedPortal?.permissions
    ? navigationItems.reduce((links: string[], navItem) => {
        const visible = navItem.visible(
          selectedPortal.type,
          selectedPortal.permissions
        )

        if (!visible) return links

        if (navItem.link) {
          links.push(navItem.link)
        }

        if (Array.isArray(navItem.items)) {
          const allowedSubItems: INavigationSubItem[] = navItem.items.filter(
            (item) => item.visible(selectedPortal.permissions)
          )

          links.push(...allowedSubItems.map((item) => item.link))
        }

        return links
      }, [])
    : []

export default getAvailableLinksPerPortal
