import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import {
  Checkbox,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Select
} from '@/components/atoms'
import {
  CheckOutSchema,
  isAssetEmpty,
  isCargoAssetMinimized,
  isChassisIdVisible
} from '@/features/gate/utils'
import {
  CargoAssetTypes,
  LoadTypes,
  PowerUnitTypes
} from '@/types/enums/transactionDetails'
import { statesOptions } from '@/utils/data'
import {
  cargoAssetTypeSelectOptions,
  cargoAssetTypesFriendlyNames,
  carriersSelectOptions,
  loadStatusSelectOptions
} from '@/utils/mappers'

interface IProps {
  cargoAssetIds: string[]
  cargoAssetLPNs: string[]
}

const CheckOutCargoAssetFormSection: FC<IProps> = (props) => {
  const { cargoAssetIds, cargoAssetLPNs } = props

  const {
    register,
    watch,
    setValue,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckOutSchema>>()

  const isManually = !watch('id')

  const puType = watch('power_unit_type')
  const assetType = watch('cargo_asset_asset_type')
  const loadStatus = watch('load_status')
  const sealMatchesPW = !!watch('seal_matchPW')
  const assetCarrier = watch('cargo_asset_carrier_name')
  const chassisName = watch('chassis_id')

  const isEmpty = isAssetEmpty(loadStatus as LoadTypes)
  const isMinimized = isCargoAssetMinimized(puType as PowerUnitTypes)
  const isChassisVisible = isChassisIdVisible(
    puType as PowerUnitTypes,
    assetType as CargoAssetTypes
  )

  return (
    <Col gap={20}>
      {!isMinimized && (
        <>
          {isManually ? (
            <Controller
              name="cargo_asset_asset_type"
              control={control}
              render={({ field, formState }) => (
                <Select
                  required
                  label="Asset Type"
                  value={field.value}
                  error={!!formState.errors.cargo_asset_asset_type}
                  helperText={formState.errors.cargo_asset_asset_type?.message}
                  options={cargoAssetTypeSelectOptions}
                  onChange={field.onChange}
                />
              )}
            />
          ) : (
            <ReadOnlyFormValue
              title="Asset Type"
              value={
                assetType ? cargoAssetTypesFriendlyNames[assetType] : undefined
              }
            />
          )}

          {isManually ? (
            <Row gap={16}>
              <Input
                {...register('cargo_asset_owner_id')}
                required
                uppercase
                fullWidth
                label="Asset ID"
                error={!!errors.cargo_asset_owner_id}
                helperText={errors.cargo_asset_owner_id?.message}
              />

              <Controller
                name="cargo_asset_carrier_name"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    required
                    label="Asset Carrier"
                    value={field.value}
                    options={carriersSelectOptions}
                    error={!!formState.errors.cargo_asset_carrier_name}
                    helperText={
                      formState.errors.cargo_asset_carrier_name?.message
                    }
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
          ) : (
            <Row gap={16}>
              <Controller
                name="cargo_asset_owner_id"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    required
                    label="Asset ID"
                    value={field.value}
                    options={cargoAssetIds.map((id) => ({
                      id,
                      label: id
                    }))}
                    error={!!formState.errors.cargo_asset_owner_id}
                    helperText={formState.errors.cargo_asset_owner_id?.message}
                    onChange={field.onChange}
                  />
                )}
              />

              <ReadOnlyFormValue title="Carrier" value={assetCarrier} />
            </Row>
          )}
        </>
      )}

      <Row gap={16}>
        <Controller
          name="load_status"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Load Status"
              value={field.value}
              options={loadStatusSelectOptions}
              error={!!formState.errors.load_status}
              helperText={formState.errors.load_status?.message}
              onChange={field.onChange}
            />
          )}
        />

        {!isEmpty && (
          <Input
            {...register('shipment_number')}
            uppercase
            fullWidth
            label="Outbound Ref #"
            error={!!errors.shipment_number}
            helperText={errors.shipment_number?.message}
          />
        )}
      </Row>

      {!isEmpty && (
        <Col items="stretch" gap={4}>
          <Controller
            name="seal_numbers"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                fullWidth
                type="multiple"
                label="Seal #"
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Checkbox
            value={sealMatchesPW}
            label="The seal matches the paperwork "
            onChange={() => setValue('seal_matchPW', !sealMatchesPW)}
          />
        </Col>
      )}

      {isChassisVisible &&
        (isManually ? (
          <Input
            {...register('chassis_id')}
            uppercase
            fullWidth
            label="Chassis ID"
            error={!!errors.chassis_id}
            helperText={errors.chassis_id?.message}
          />
        ) : (
          <ReadOnlyFormValue title="Chassis ID" value={chassisName} />
        ))}

      {!isMinimized &&
        (isManually ? (
          <Row gap={16}>
            <Input
              {...register('cargo_asset_license_plate_number')}
              uppercase
              fullWidth
              required
              label="LPN"
              error={!!errors.cargo_asset_license_plate_number}
              helperText={errors.cargo_asset_license_plate_number?.message}
            />

            <Controller
              name="cargo_asset_license_plate_state"
              control={control}
              render={({ field, fieldState }) => (
                <Select
                  required
                  label="LPN State/Region"
                  value={field.value}
                  options={statesOptions}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  onChange={field.onChange}
                />
              )}
            />
          </Row>
        ) : (
          <Controller
            name="cargo_asset_license_plate_number"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="LPN"
                value={field.value}
                options={cargoAssetLPNs.map((lpn) => ({
                  id: lpn,
                  label: lpn
                }))}
                error={!!formState.errors.cargo_asset_license_plate_number}
                helperText={
                  formState.errors.cargo_asset_license_plate_number?.message
                }
                onChange={field.onChange}
              />
            )}
          />
        ))}
    </Col>
  )
}

export default CheckOutCargoAssetFormSection
