import * as yup from 'yup'

const EditUserSchema = yup.object({
  first_name: yup
    .string()
    .trim()
    .max(20, 'Max length is 50')
    .required('First Name is required'),
  last_name: yup
    .string()
    .trim()
    .max(20, 'Max length is 50')
    .required('Last Name is required'),
  email: yup
    .string()
    .matches(/^[\w.+-]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email')
    .required('Email is required'),
  enterpriseEnabled: yup.boolean().required(),
  siteEnabled: yup.boolean().required(),
  isAnyRoleEnabled: yup
    .boolean()
    .default(false)
    .test('isAnyRoleEnabled', 'Role is required', function () {
      const { enterpriseEnabled, siteEnabled } = this.parent

      return enterpriseEnabled || siteEnabled
    }),
  roles: yup.array().when('enterpriseEnabled', {
    is: true,
    then: (schema) =>
      schema.min(1, 'Role is required').required('Role is required'),
    otherwise: (schema) => schema
  }),
  site_roles: yup.array().when('siteEnabled', {
    is: true,
    then: (schema) =>
      schema.of(
        yup.object().shape({
          id: yup.string().required(),
          site_id: yup.string().required('Site is required'),
          roles: yup
            .array()
            .min(1, 'Role is required')
            .required('Role is required')
        })
      ),
    otherwise: (schema) => schema
  })
})

export default EditUserSchema
