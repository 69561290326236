import AddIcon from '@mui/icons-material/Add'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { FormHelperText } from '@mui/material'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Button, Col, Row, Select, Switch, Text } from '@/components/atoms'
import classes from '@/components/atoms/Input/classes'
import { ISite, ISiteRoles } from '@/features/user-management/api/types'
import styles from '@/features/user-management/components/AddEditUserModal/AddEditUserModal.module.scss'
import { EditUserSchema } from '@/features/user-management/utils'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import {
  enterpriseRolesSelectOptions,
  getSiteRolesSelectOptions
} from '@/utils/data'
import { generateRandomId } from '@/utils/helpers'

interface IProps {
  sites: ISite[]
}

const PortalsAndRolesFormSection: FC<IProps> = (props) => {
  const { sites } = props

  const {
    watch,
    setValue,
    trigger,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof EditUserSchema>>()

  const enterpriseEnabled = watch('enterpriseEnabled')
  const siteEnabled = watch('siteEnabled')

  const siteRoles = watch('site_roles') || []
  const enterpriseRoles = watch('roles') || []

  const addSite = () => {
    setValue('site_roles', [
      ...siteRoles,
      {
        id: generateRandomId(),
        site_id: undefined,
        roles: []
      }
    ])
  }

  const togglePortalEnabled = (type: 'enterpriseEnabled' | 'siteEnabled') => {
    setValue(type, !watch(type))

    if (type === 'siteEnabled' && !siteEnabled && !siteRoles.length) {
      addSite()
    }

    trigger('isAnyRoleEnabled')
  }

  const removeSite = (id: string) => {
    if (siteRoles.length === 1) {
      setValue('siteEnabled', false)
    }

    setValue(
      'site_roles',
      siteRoles.filter((item) => item.id !== id)
    )
  }

  const updateSite = (
    id: string,
    field: 'site_id' | 'roles',
    value: string | string[] | undefined
  ) => {
    setValue(
      'site_roles',
      siteRoles.map((item) =>
        item.id === id
          ? {
              ...item,
              [field]:
                field === 'site_id' ? (value as string) : (value as string[])
            }
          : item
      )
    )
  }

  const filterOutSiteOptions = (id: string) =>
    sites
      .filter((site) =>
        siteRoles.every(
          (item: ISiteRoles) => item.id === id || item.site_id !== site.id
        )
      )
      .map((site) => ({ id: site.id, label: site.display_name }))

  return (
    <Col gap={8} items="stretch" className="tw-mt-24">
      <Col>
        <Text
          type={TextTypes.TEXT_LG}
          weight={FontWeight.SEMIBOLD}
          color={Color.gray700}
        >
          Roles
        </Text>

        {!!errors.isAnyRoleEnabled && (
          <Text>
            <FormHelperText
              error
              variant="standard"
              classes={classes.helperTextClasses}
            >
              {errors.isAnyRoleEnabled?.message}
            </FormHelperText>
          </Text>
        )}
      </Col>

      <Switch
        value={enterpriseEnabled}
        label="Enterprise Portal"
        onChange={() => togglePortalEnabled('enterpriseEnabled')}
      />

      {enterpriseEnabled && (
        <Col className={styles.roleListContainer}>
          <Select
            multiple
            required
            label="Role"
            fullWidth={false}
            value={enterpriseRoles}
            options={enterpriseRolesSelectOptions}
            error={!!errors.roles}
            helperText={errors.roles?.message}
            onChange={(value) => setValue('roles', value as string[])}
          />
        </Col>
      )}

      <Switch
        value={siteEnabled}
        label="Site Portal"
        onChange={() => togglePortalEnabled('siteEnabled')}
      />

      {siteEnabled && (
        <>
          <Col className={styles.roleListContainer} gap={8}>
            {siteRoles.map((item, index) => (
              <Row gap={8} key={item.id}>
                <Select
                  required
                  label="Site"
                  value={item.site_id}
                  options={filterOutSiteOptions(item.id)}
                  // @ts-ignore
                  error={!!errors?.site_roles?.[`${index}`]?.site_id}
                  helperText={
                    // @ts-ignore
                    errors?.site_roles?.[`${index}`]?.site_id?.message
                  }
                  onChange={(value) => updateSite(item.id, 'site_id', value)}
                />

                <Select
                  multiple
                  required
                  label="Role"
                  value={item.roles}
                  options={getSiteRolesSelectOptions(item.roles)}
                  // @ts-ignore
                  error={!!errors.site_roles?.[`${index}`]?.roles}
                  helperText={
                    // @ts-ignore
                    errors.site_roles?.[`${index}`]?.roles?.message
                  }
                  onChange={(value) => updateSite(item.id, 'roles', value)}
                />

                <div
                  tabIndex={0}
                  className={styles.deleteIcon}
                  onClick={() => removeSite(item.id)}
                >
                  <DeleteOutlineIcon />
                </div>
              </Row>
            ))}
          </Col>

          {siteRoles.length < sites.length && (
            <Button
              type="outlined"
              startIcon={<AddIcon />}
              className="tw-self-start"
              onClick={addSite}
            >
              Add Site
            </Button>
          )}
        </>
      )}
    </Col>
  )
}

export default PortalsAndRolesFormSection
