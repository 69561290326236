import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import { Col, Text } from '@/components/atoms'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './CardRow.module.scss'

interface IProps {
  titleOne: string
  titleTwo?: string
  valueOne: string | null | undefined
  valueTwo?: string | null | undefined
  secondBold?: boolean
  button?: ReactNode
  className?: string
}

const CardRow: FC<IProps> = (props) => {
  const {
    titleOne,
    titleTwo,
    valueOne,
    valueTwo,
    secondBold,
    className,
    button
  } = props

  const firstColumn = () => (
    <Col gap={4}>
      <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
        {titleOne}
      </Text>

      <Text
        type={TextTypes.TEXT_SM}
        color={Color.gray700}
        weight={FontWeight.REGULAR}
      >
        {valueOne || '-'}
      </Text>
    </Col>
  )

  const secondColumn = () => {
    if (!titleTwo) return button

    return (
      <Col gap={4}>
        <Text type={TextTypes.TEXT_XS} color={Color.gray600}>
          {titleTwo}
        </Text>

        <Text
          type={TextTypes.TEXT_SM}
          color={Color.gray700}
          weight={secondBold ? FontWeight.BOLD : FontWeight.REGULAR}
        >
          {valueTwo || '-'}
        </Text>
      </Col>
    )
  }

  return (
    <div className={clsx(styles.gridRow, className)}>
      {firstColumn()}
      {secondColumn()}
    </div>
  )
}

export default CardRow
