import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

import { TableActions, TableColumnType } from '@/types/enums/table'
import { ITableColumn } from '@/types/interfaces/table'

const userManagementColumns = (): ITableColumn[] => [
  {
    id: 'name',
    title: 'Name',
    type: TableColumnType.Text,
    className: 'color-green500',
    sortable: true
  },
  {
    id: 'email',
    title: 'Email',
    type: TableColumnType.Text,
    sortable: true
  },
  {
    id: 'last_login',
    title: 'Last Login',
    type: TableColumnType.Date
  },
  {
    id: 'disabled',
    title: 'Status',
    tooltip:
      'Activate or Deactivate user access. When deactivated, user will not be able to login or access data. When activated, access is restored.',
    type: TableColumnType.Toggle,
    text: ['Active', 'Inactive']
  },
  {
    id: 'actions',
    title: '',
    type: TableColumnType.Actions,
    className: 'tw-w-55',
    actions: [
      { id: TableActions.Edit, label: 'Edit', icon: <CreateOutlinedIcon /> },
      {
        id: TableActions.Delete,
        label: 'Delete',
        className: 'color-red500',
        icon: <DeleteOutlineOutlinedIcon />
      }
    ]
  }
]

export default userManagementColumns
