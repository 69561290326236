import { baseApi, withParams } from '@/api'

import endpoints from './endpoints'
import {
  CreateGateTransactionRequest,
  CreateGateTransactionResponse,
  FetchAccountsRequest,
  FetchAccountsResponse,
  FetchGateDriversRequest,
  FetchGateDriversResponse,
  FetchGatesRequest,
  FetchGatesResponse,
  FetchGateTransactionsRequest,
  FetchGateTransactionsResponse,
  FetchLanesRequest,
  FetchLanesResponse,
  FetchPresignedUrlRequest,
  FetchPresignedUrlResponse,
  FetchQueueEventsRequest,
  FetchQueueEventsResponse
} from '@/features/gate/api/types'
import { ISelectOption } from '@/types/interfaces/ui'

export const gateApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGates: builder.query<FetchGatesResponse, FetchGatesRequest>({
      query: ({ org_id, site_id }) => {
        return withParams(endpoints.GET_GATES, { org_id, site_id })
      }
    }),

    fetchLanes: builder.query<FetchLanesResponse, FetchLanesRequest>({
      query: ({ org_id, site_id, gate_id }) => {
        return withParams(endpoints.GET_LANES, { org_id, site_id, gate_id })
      }
    }),

    fetchGateTransactions: builder.query<
      FetchGateTransactionsResponse,
      FetchGateTransactionsRequest
    >({
      query: ({ org_id, site_id, ...params }) => ({
        url: withParams(endpoints.GET_GATE_TRANSACTIONS, { org_id, site_id }),
        params
      })
    }),

    fetchGateDrivers: builder.query<
      FetchGateDriversResponse,
      FetchGateDriversRequest
    >({
      query: ({ org_id, site_id, ...params }) => ({
        url: withParams(endpoints.GET_DRIVERS, { org_id, site_id }),
        params
      })
    }),

    fetchAccounts: builder.query<ISelectOption[], FetchAccountsRequest>({
      query: ({ org_id, site_id }) => ({
        url: withParams(endpoints.GET_ACCOUNTS, { org_id, site_id })
      }),

      transformResponse: (response: FetchAccountsResponse) => {
        const accounts = response?.data?.accounts || []

        return accounts.map((account) => ({
          id: account,
          label: account
        }))
      }
    }),

    fetchQueueEvents: builder.query<
      FetchQueueEventsResponse,
      FetchQueueEventsRequest
    >({
      query: ({ org_id, lane_id, ...params }) => ({
        url: withParams(endpoints.GET_QUEUE_EVENTS, { org_id, lane_id }),
        params
      })
    }),

    getPresignedUl: builder.query<string, FetchPresignedUrlRequest>({
      query: ({ org_id, site_id, ...params }) => ({
        url: withParams(endpoints.GET_PRESIGNED_URL, { org_id, site_id }),
        params
      }),

      transformResponse: (response: FetchPresignedUrlResponse) =>
        response?.data?.url || ''
    }),

    createGateTransaction: builder.mutation<
      CreateGateTransactionResponse,
      CreateGateTransactionRequest
    >({
      query: ({ params, body }) => {
        return {
          url: withParams(endpoints.CREATE_GATE_TRANSACTION, params),
          method: 'POST',
          body
        }
      }
    })
  })
})

export const {
  useFetchGatesQuery,
  useLazyFetchGatesQuery,
  useFetchLanesQuery,
  useFetchGateDriversQuery,
  useFetchQueueEventsQuery,
  useFetchGateTransactionsQuery,
  useFetchAccountsQuery,
  useGetPresignedUlQuery,
  useCreateGateTransactionMutation
} = gateApi
