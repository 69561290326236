export const TWO_HOURS_IN_MILLISECONDS = 7200000
export const TWENTY_FOUR_HOURS_IN_MILLISECONDS = 86399000

export const TRUCK_IMAGES = [
  'https://cdn.britannica.com/17/126517-050-9CDCBDDF/semi-semitrailer-truck-tractor-highway.jpg',
  'https://media.hswstatic.com/eyJidWNrZXQiOiJjb250ZW50Lmhzd3N0YXRpYy5jb20iLCJrZXkiOiJnaWZcL2dldHR5aW1hZ2VzLTExMzg1ODIyNi5qcGciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjgyOH19fQ==',
  'https://i.ytimg.com/vi/H1emIRZGIzg/maxresdefault.jpg',
  'https://goloadup.com/wp-content/uploads/2023/12/box-truck-weight-limits.jpg',
  'https://www.deere.co.in/assets/images/region-1/products/tractors/john-deere-d-series-tractor.jpg'
]

export const BREAKPOINTS = {
  XL: 1440,
  LG: 1200,
  MD: 744,
  SM: 360
}

export const PAGINATION_ITEMS_PER_PAGE = 20

export const CARRIERS: string[] = ['Ryder', 'NFI']
