import IosShareIcon from '@mui/icons-material/IosShare'
import { useMemo, useState } from 'react'

import { Button, HeaderActionPortal } from '@/components/atoms'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import {
  useFetchAccountsQuery,
  useFetchGateTransactionsQuery
} from '@/features/gate/api'
import {
  transactionsColumns,
  transactionsFilters,
  transactionsHighOrderColumns
} from '@/features/gate/utils'
import { useStore } from '@/store'
import { PaginationQuery, SearchQuery } from '@/types/interfaces/api'

const GateTransactionTemplate = () => {
  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [{ current_page, items_per_page, search_query }, setQuery] = useState<
    PaginationQuery & SearchQuery
  >({
    current_page: 1,
    items_per_page: PAGINATION_ITEMS_PER_PAGE,
    search_query: ''
  })

  const { data, isFetching } = useFetchGateTransactionsQuery(
    {
      org_id,
      site_id,
      current_page,
      items_per_page,
      search_query
    },
    { skip: !org_id || !site_id }
  )

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const filters = useMemo(() => transactionsFilters(accounts), [accounts])

  return (
    <WithHeaderTemplate title="Transactions">
      <HeaderActionPortal>
        <Button type="outlined" startIcon={<IosShareIcon />}>
          Export
        </Button>
      </HeaderActionPortal>

      <div className="tw-flex-1 tw-flex">
        <Table
          name="gate-transactions"
          idAccessor="id"
          loading={isFetching}
          rows={data?.data || []}
          filters={filters}
          columns={transactionsColumns}
          highOrderColumns={transactionsHighOrderColumns}
          searchPlaceholder="Search Transaction"
          perPage={items_per_page}
          currentPage={current_page}
          totalPages={data?.pagination?.total_pages}
          totalItems={data?.pagination?.total_items_count}
          onPageChange={(page) =>
            setQuery((prev) => ({ ...prev, current_page: page }))
          }
          onSearch={(page) =>
            setQuery((prev) => ({ ...prev, search_query: page }))
          }
        />
      </div>
    </WithHeaderTemplate>
  )
}

export default GateTransactionTemplate
