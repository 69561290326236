import React, { useState } from 'react'

import { Col, Loader } from '@/components/atoms'
import { TitleWithDescription } from '@/components/molecules'
import { WithHeaderTemplate } from '@/components/templates'
import { CheckInModal, CheckOutModal, Queues } from '@/features/gate'
import { useFetchGatesQuery, useFetchLanesQuery } from '@/features/gate/api'
import { LaneDirection } from '@/features/gate/enums'
import { Lane } from '@/features/gate/types'
import { useModal } from '@/hooks'
import { useStore } from '@/store'

const GateQueueTemplate = () => {
  const { org, selectedPortal } = useStore((store) => store.user || {})

  const { visible, openModal, closeModal } = useModal<LaneDirection>()

  const [selectedLane, setSelectedLane] = useState<Lane | undefined>()

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const { data: gateResponse, isFetching: gatesLoading } = useFetchGatesQuery(
    { org_id, site_id },
    { skip: !org_id || !site_id }
  )

  const gate_id = gateResponse?.data?.gates?.[0]?.id || ''

  const { data: lanesResponse, isFetching: lanesLoading } = useFetchLanesQuery(
    { org_id, site_id, gate_id },
    { skip: !org_id || !site_id || !gate_id }
  )

  const loading = gatesLoading || lanesLoading

  const lanes = !gate_id ? [] : lanesResponse?.data?.lanes || []

  const onCheckInOutManually = (modalType: LaneDirection, lane: Lane) => {
    openModal(modalType)
    setSelectedLane(lane)
  }

  const closeCheckInOutModal = () => {
    closeModal()
    setSelectedLane(undefined)
  }

  return (
    <WithHeaderTemplate title="Gate Queue">
      {visible === LaneDirection.Arriving && (
        <CheckInModal
          gateId={gate_id}
          lane={selectedLane as Lane}
          closeModal={closeCheckInOutModal}
        />
      )}

      {visible === LaneDirection.Departing && (
        <CheckOutModal
          gateId={gate_id}
          lane={selectedLane as Lane}
          closeModal={closeCheckInOutModal}
        />
      )}

      {loading || !lanes.length ? (
        <Col items="center" justify="center" gap={8} className="tw-h-full">
          {loading ? (
            <Loader />
          ) : (
            <TitleWithDescription
              title={!gate_id ? 'There is no gates' : 'There is no lanes'}
              description={
                !gate_id
                  ? 'The selected site does not contain any gates.'
                  : 'The selected site does not contain any lanes.'
              }
            />
          )}
        </Col>
      ) : (
        <Queues
          lanes={lanes}
          gateId={gate_id}
          onCheckInOutManually={onCheckInOutManually}
        />
      )}
    </WithHeaderTemplate>
  )
}

export default GateQueueTemplate
