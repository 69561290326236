import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Col, Input, Row, Select } from '@/components/atoms'
import { SuggestedPowerUnitID } from '@/features/gate'
import { CheckInSchema, isUsDOTVisible } from '@/features/gate/utils'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import {
  carriersSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions,
  powerUnitTypeSelectOptions
} from '@/utils/mappers'

interface IProps {
  accounts: ISelectOption[]
  suggestedIds: string[]
}

const CheckInPowerUnitFormSection: FC<IProps> = (props) => {
  const { accounts, suggestedIds } = props

  const {
    control,
    watch,
    register,
    setValue,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckInSchema>>()

  const [selectedSuggestedId, setSelectedSuggestedId] = useState<
    string | undefined
  >()

  const powerUnitId = watch('power_unit_owner_id')
  const appointmentType = watch('appointment_type')

  const showUSDOT = isUsDOTVisible(appointmentType)

  const onSelectSuggestedItem = (value: string) => {
    setSelectedSuggestedId(value)
    setValue('power_unit_owner_id', value, { shouldValidate: true })
  }

  useEffect(() => {
    if (powerUnitId !== selectedSuggestedId) {
      setSelectedSuggestedId(undefined)
    }
  }, [powerUnitId])

  return (
    <Col items="stretch" gap={20}>
      <Controller
        name="power_unit_type"
        control={control}
        render={({ field, formState }) => (
          <Select
            required
            label="Type"
            value={field.value}
            error={!!formState.errors.power_unit_type}
            helperText={formState.errors.power_unit_type?.message}
            options={powerUnitTypeSelectOptions}
            onChange={field.onChange}
          />
        )}
      />

      <Col gap={7}>
        <Controller
          name="power_unit_owner_id"
          control={control}
          render={({ field, formState }) => (
            <Input
              required
              uppercase
              fullWidth
              {...field}
              label="ID"
              error={!!formState.errors.power_unit_owner_id}
              helperText={formState.errors.power_unit_owner_id?.message}
            />
          )}
        />

        {suggestedIds.length > 1 && (
          <SuggestedPowerUnitID
            suggestedItems={suggestedIds || []}
            selectedId={selectedSuggestedId}
            onChange={onSelectSuggestedItem}
          />
        )}
      </Col>

      <Row gap={16}>
        {showUSDOT && (
          <Input
            {...register('power_unit_carrier_usdot')}
            required
            fullWidth
            digitsOnly
            type="number"
            label="US DOT #"
            error={!!errors.power_unit_carrier_usdot}
            helperText={errors.power_unit_carrier_usdot?.message}
          />
        )}

        <Controller
          name="power_unit_carrier_name"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Carrier"
              value={field.value}
              options={carriersSelectOptions}
              error={!!formState.errors.power_unit_carrier_name}
              helperText={formState.errors.power_unit_carrier_name?.message}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('power_unit_license_plate_number')}
          required
          uppercase
          fullWidth
          label="LPN"
          error={!!errors.power_unit_license_plate_number}
          helperText={errors.power_unit_license_plate_number?.message}
        />

        <Controller
          name="power_unit_license_plate_state"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="LPN State/Region"
              value={field.value}
              options={statesOptions}
              error={!!formState.errors.power_unit_license_plate_state}
              helperText={
                formState.errors.power_unit_license_plate_state?.message
              }
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Input
          {...register('power_unit_vin')}
          uppercase
          fullWidth
          label="VIN"
          error={!!errors.power_unit_vin}
          helperText={errors.power_unit_vin?.message}
        />

        <Controller
          name="power_unit_weight_class"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Class"
              value={field.value}
              options={classListSelectOptions}
              error={!!formState.errors.power_unit_weight_class}
              helperText={formState.errors.power_unit_weight_class?.message}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Row gap={16}>
        <Controller
          name="power_unit_fuel_type"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Fuel Type"
              value={field.value}
              options={fuelTypeSelectOptions}
              error={!!formState.errors.power_unit_fuel_type}
              helperText={formState.errors.power_unit_fuel_type?.message}
              onChange={field.onChange}
            />
          )}
        />

        <Controller
          name="account_name"
          control={control}
          render={({ field, formState }) => (
            <Select
              multiple
              label="Account"
              value={field.value}
              options={accounts}
              error={!!formState.errors.account_name}
              helperText={formState.errors.account_name?.message}
              onChange={field.onChange}
            />
          )}
        />
      </Row>
    </Col>
  )
}

export default CheckInPowerUnitFormSection
