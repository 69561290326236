import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import config from '@/config'
import { RootState } from '@/store'

export * from './helpers'

export const baseApi = createApi({
  reducerPath: 'api',
  tagTypes: ['User', 'Invite', 'Site', 'Role', 'SiteRole', 'ISRRecord'],
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).user.authToken

      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
        headers.set('Cf-Access-Jwt-Assertion', token)
        headers.set('Content-Type', 'application/json')
      }

      return headers
    }
  }),
  endpoints: () => ({})
})
