import { matchIsValidTel } from 'mui-tel-input'
import * as yup from 'yup'

import {
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import {
  AppointmentTypes,
  CargoAssetTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'

const validations = {
  isCargoAssetVisible: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType, false)
  },

  isManually: {
    keys: 'id',
    validate: (id: string | undefined) => !id
  },

  isNotMinimized: {
    keys: ['appointment_type', 'power_unit_type'],
    validate: (appointmentType: AppointmentTypes, puType: PowerUnitTypes) =>
      isCargoAssetVisible(appointmentType, puType, false) &&
      !isCargoAssetMinimized(puType)
  },

  isManuallyAndNotMinimized: {
    keys: ['id', 'appointment_type', 'power_unit_type'],
    validate: (
      id: string | undefined,
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes
    ) =>
      validations.isManually.validate(id) &&
      validations.isNotMinimized.validate(appointmentType, puType)
  },

  isNotEmpty: {
    keys: ['appointment_type', 'power_unit_type', 'load_status'],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      loadStatus: LoadTypes
    ) =>
      validations.isCargoAssetVisible.validate(appointmentType, puType) &&
      !isAssetEmpty(loadStatus)
  },

  isChassisVisible: {
    keys: [
      'appointment_type',
      'power_unit_type',
      'cargo_asset_asset_type',
      'id'
    ],
    validate: (
      appointmentType: AppointmentTypes,
      puType: PowerUnitTypes,
      assetType: CargoAssetTypes,
      id: string
    ) =>
      validations.isCargoAssetVisible.validate(appointmentType, puType) &&
      validations.isManually.validate(id) &&
      isChassisIdVisible(puType, assetType)
  }
}

const CheckOutSchema = yup.object({
  // Need this only to validate is manually or from lane card
  id: yup.string(),

  // General Section
  date: yup.date(),
  appointment_type: yup
    .mixed<AppointmentTypes>()
    .oneOf(Object.values(AppointmentTypes))
    .required('Appointment Type is required'),

  // Power Unit Section
  power_unit_owner_id: yup.string().required('ID is required'),
  power_unit_type: yup
    .mixed<PowerUnitTypes>()
    .oneOf(Object.values(PowerUnitTypes))
    .when(validations.isManually.keys, {
      is: validations.isManually.validate,
      then: (schema) => schema.required('Type is required'),
      otherwise: (schema) => schema
    }),
  power_unit_license_plate_number: yup
    .string()
    .trim()
    .min(1, 'LPN is required')
    .max(20, 'Max length is 20')
    .required('LPN is required'),
  power_unit_license_plate_state: yup
    .string()
    .when(validations.isManually.keys, {
      is: validations.isManually.validate,
      then: (schema) => schema.required('State is required'),
      otherwise: (schema) => schema
    }),
  power_unit_carrier_name: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) => schema.required('Carrier is required'),
    otherwise: (schema) => schema
  }),
  power_unit_carrier_usdot: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) =>
      schema
        .test(
          'len',
          'Min length is 3',
          (val) => !!val && val?.toString()?.length >= 3
        )
        .test(
          'len',
          'Max length is 20',
          (val) => !!val && val?.toString()?.length <= 20
        )
        .required('US DOT # is required'),
    otherwise: (schema) => schema
  }),
  power_unit_vin: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) => schema.max(20, 'Max length is 20'),
    otherwise: (schema) => schema
  }),
  power_unit_weight_class: yup
    .mixed<WeightClasses>()
    .oneOf(Object.values(WeightClasses))
    .when(validations.isManually.keys, {
      is: validations.isManually.validate,
      then: (schema) => schema.required('Class is required'),
      otherwise: (schema) => schema
    }),
  power_unit_fuel_type: yup
    .mixed<FuelTypes>()
    .oneOf(Object.values(FuelTypes))
    .when(validations.isManually.keys, {
      is: validations.isManually.validate,
      then: (schema) => schema.required('Fuel Type is required'),
      otherwise: (schema) => schema
    }),
  account_name: yup.array(),

  // Driver Section
  driver_first_name: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) =>
      schema.max(50, 'Max length is 50').required('First Name is required'),
    otherwise: (schema) => schema
  }),
  driver_last_name: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) =>
      schema.max(50, 'Max length is 50').required('Last Name is required'),
    otherwise: (schema) => schema
  }),
  driver_license_number: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) =>
      schema.max(20, 'Max length is 20').required('Driver License is required'),
    otherwise: (schema) => schema
  }),
  driver_phone_number: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) =>
      schema
        .test('format', 'Not valid phone number', (val) =>
          val
            ? matchIsValidTel(val, {
                onlyCountries: ['US', 'MX', 'CA']
              })
            : true
        )
        .notRequired(),
    otherwise: (schema) => schema
  }),
  driver_license_state: yup.string().when(validations.isManually.keys, {
    is: validations.isManually.validate,
    then: (schema) => schema.required('State is required'),
    otherwise: (schema) => schema
  }),

  // Cargo Asset Section
  cargo_asset_asset_type: yup
    .mixed<CargoAssetTypes>()
    .oneOf(Object.values(CargoAssetTypes))
    .when(validations.isManuallyAndNotMinimized.keys, {
      is: validations.isManuallyAndNotMinimized.validate,
      then: (schema) => schema.required('Asset type is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_owner_id: yup.string().when(validations.isNotMinimized.keys, {
    is: validations.isNotMinimized.validate,
    then: (schema) => schema.required('ID is required'),
    otherwise: (schema) => schema
  }),
  cargo_asset_carrier_name: yup
    .string()
    .when(validations.isManuallyAndNotMinimized.keys, {
      is: validations.isManuallyAndNotMinimized.validate,
      then: (schema) => schema.required('Carrier is required'),
      otherwise: (schema) => schema
    }),
  chassis_id: yup.string().when(validations.isChassisVisible.keys, {
    is: validations.isChassisVisible.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  cargo_asset_license_plate_number: yup
    .string()
    .trim()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) =>
        schema.max(20, 'Max length is 20').required('LPN is required'),
      otherwise: (schema) => schema
    }),
  cargo_asset_license_plate_state: yup
    .string()
    .when(validations.isNotMinimized.keys, {
      is: validations.isNotMinimized.validate,
      then: (schema) => schema.required('State is required'),
      otherwise: (schema) => schema
    }),
  load_status: yup.string().when(validations.isCargoAssetVisible.keys, {
    is: validations.isCargoAssetVisible.validate,
    then: (schema) => schema.required('Load Status is required'),
    otherwise: (schema) => schema
  }),
  shipment_number: yup.string().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.max(50, 'Max length is 50'),
    otherwise: (schema) => schema
  }),
  seal_numbers: yup.array().when(validations.isNotEmpty.keys, {
    is: validations.isNotEmpty.validate,
    then: (schema) => schema.of(yup.string().max(50, 'Max length is 50')),
    otherwise: (schema) => schema
  }),
  seal_matchPW: yup.boolean().notRequired()
})

export default CheckOutSchema
