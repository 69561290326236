import { EnterpriseRoles, SiteRoles } from '@/types/enums/global'
import { ISelectOption } from '@/types/interfaces/ui'

export const enterpriseRolesSelectOptions = [
  { id: EnterpriseRoles.Admin, label: 'Admin' }
]

export const getSiteRolesSelectOptions = (
  selectedRoles: SiteRoles[]
): ISelectOption[] => [
  {
    id: SiteRoles.Admin,
    label: 'Admin',
    disabled: selectedRoles.includes(SiteRoles.ViewOnly)
  },
  {
    id: SiteRoles.ISRManager,
    label: 'ISR Manager',
    disabled: selectedRoles.includes(SiteRoles.ViewOnly)
  },
  {
    id: SiteRoles.Gate,
    label: 'Gate',
    disabled: selectedRoles.includes(SiteRoles.ViewOnly)
  },
  {
    id: SiteRoles.ViewOnly,
    label: 'View Only',
    disabled: !!selectedRoles.length
  }
]
