import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined'

import {
  IEnterprisePortalPermissions,
  ISitePortalPermissions
} from '@/features/auth/types'
import ROUTES from '@/router/routes'
import { PortalTypes } from '@/types/enums/global'
import { INavigationItem } from '@/types/interfaces/ui'

export const navigationItems: INavigationItem[] = [
  {
    id: 'gate',
    title: 'Gate',
    icon: <LocalShippingOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: ISitePortalPermissions) => {
      const hasAccessToGateTransactions = permissions.gate_transaction
      const hasAccessToGateQueueOrDrivers =
        permissions.gate_queue || permissions.onsite_driver

      return (
        portalType === PortalTypes.Site &&
        hasAccessToGateTransactions &&
        hasAccessToGateQueueOrDrivers
      )
    },

    items: [
      {
        id: 'queue',
        title: 'Queue',
        link: ROUTES.GATE_QUEUE,
        visible: (permissions: ISitePortalPermissions) => permissions.gate_queue
      },
      {
        id: 'transactions',
        title: 'Transactions',
        link: ROUTES.GATE_TRANSACTIONS,
        visible: (permissions: ISitePortalPermissions) =>
          permissions.gate_transaction
      },
      {
        id: 'drivers',
        title: 'Drivers',
        link: ROUTES.GATE_DRIVERS,
        visible: (permissions: ISitePortalPermissions) =>
          permissions.onsite_driver
      }
    ]
  },
  {
    id: 'yard',
    title: 'Yard',
    icon: <ContentPasteOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: ISitePortalPermissions) =>
      portalType === PortalTypes.Site && permissions.onsite_cargo_asset,
    items: [
      {
        id: 'cargo-asset',
        title: 'Cargo Asset',
        link: ROUTES.YARD_CARGO_ASSET,
        visible: (permissions: ISitePortalPermissions) =>
          permissions.onsite_cargo_asset
      }
    ]
  },
  {
    id: 'isr',
    title: 'ISR',
    link: ROUTES.ISR,
    icon: <WbSunnyOutlinedIcon />,
    visible: (portalType: PortalTypes, permissions: ISitePortalPermissions) =>
      portalType === PortalTypes.Site && permissions.isr_record
  },
  {
    id: 'admin',
    title: 'Administration',
    icon: <AdminPanelSettingsOutlinedIcon />,
    visible: () => false,
    items: [
      {
        id: 'mismatches',
        title: 'Mismatches',
        link: ROUTES.ADMIN_MISMATCHES,
        visible: (permissions: ISitePortalPermissions) => permissions.mismatches
      },
      {
        id: 'video',
        title: 'Video',
        link: ROUTES.ADMIN_VIDEO,
        visible: (permissions: ISitePortalPermissions) => permissions.video
      }
    ]
  },
  {
    id: 'gate-view-only',
    title: 'Gate',
    icon: <LocalShippingOutlinedIcon />,
    link: ROUTES.GATE_TRANSACTIONS,
    visible: (portalType: PortalTypes, permissions: ISitePortalPermissions) => {
      const hasAccessToGateTransactions = permissions.gate_transaction
      const hasNoAccessToGateQueueAndDrivers =
        !permissions.gate_queue && !permissions.onsite_driver

      return (
        portalType === PortalTypes.Site &&
        hasAccessToGateTransactions &&
        hasNoAccessToGateQueueAndDrivers
      )
    }
  },
  {
    id: 'users',
    title: 'Users',
    link: ROUTES.USERS,
    icon: <GroupOutlinedIcon />,
    visible: (
      portalType: PortalTypes,
      permissions: IEnterprisePortalPermissions
    ) => portalType === PortalTypes.Enterprise && permissions.users
  }
]
