import { ISelectOption } from '@/types/interfaces/ui'

const statesOptions: ISelectOption[] = [
  { id: 'ALABAMA', label: 'Alabama' },
  { id: 'ALASKA', label: 'Alaska' },
  { id: 'ARIZONA', label: 'Arizona' },
  { id: 'ARKANSAS', label: 'Arkansas' },
  { id: 'CALIFORNIA', label: 'California' },
  { id: 'COLORADO', label: 'Colorado' },
  { id: 'CONNECTICUT', label: 'Connecticut' },
  { id: 'DELAWARE', label: 'Delaware' },
  { id: 'FLORIDA', label: 'Florida' },
  { id: 'GEORGIA', label: 'Georgia' },
  { id: 'HAWAII', label: 'Hawaii' },
  { id: 'IDAHO', label: 'Idaho' },
  { id: 'ILLINOIS', label: 'Illinois' },
  { id: 'INDIANA', label: 'Indiana' },
  { id: 'IOWA', label: 'Iowa' },
  { id: 'KANSAS', label: 'Kansas' },
  { id: 'KENTUCKY', label: 'Kentucky' },
  { id: 'LOUISIANA', label: 'Louisiana' },
  { id: 'MAINE', label: 'Maine' },
  { id: 'MARYLAND', label: 'Maryland' },
  { id: 'MASSACHUSETTS', label: 'Massachusetts' },
  { id: 'MICHIGAN', label: 'Michigan' },
  { id: 'MINNESOTA', label: 'Minnesota' },
  { id: 'MISSISSIPPI', label: 'Mississippi' },
  { id: 'MISSOURI', label: 'Missouri' },
  { id: 'MONTANA', label: 'Montana' },
  { id: 'NEBRASKA', label: 'Nebraska' },
  { id: 'NEVADA', label: 'Nevada' },
  { id: 'NEW_HAMPSHIRE', label: 'New Hampshire' },
  { id: 'NEW_JERSEY', label: 'New Jersey' },
  { id: 'NEW_MEXICO', label: 'New Mexico' },
  { id: 'NEW_YORK', label: 'New York' },
  { id: 'NORTH_CAROLINA', label: 'North Carolina' },
  { id: 'NORTH_DAKOTA', label: 'North Dakota' },
  { id: 'OHIO', label: 'Ohio' },
  { id: 'OKLAHOMA', label: 'Oklahoma' },
  { id: 'OREGON', label: 'Oregon' },
  { id: 'PENNSYLVANIA', label: 'Pennsylvania' },
  { id: 'RHODE_ISLAND', label: 'Rhode Island' },
  { id: 'SOUTH_CAROLINA', label: 'South Carolina' },
  { id: 'SOUTH_DAKOTA', label: 'South Dakota' },
  { id: 'TENNESSEE', label: 'Tennessee' },
  { id: 'TEXAS', label: 'Texas' },
  { id: 'UTAH', label: 'Utah' },
  { id: 'VERMONT', label: 'Vermont' },
  { id: 'VIRGINIA', label: 'Virginia' },
  { id: 'WASHINGTON', label: 'Washington' },
  { id: 'WEST_VIRGINIA', label: 'West Virginia' },
  { id: 'WISCONSIN', label: 'Wisconsin' },
  { id: 'WYOMING', label: 'Wyoming' },
  { id: 'MEXICO', label: 'Mexico' },
  { id: 'CANADA', label: 'Canada' }
]

export default statesOptions
