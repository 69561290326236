import clsx from 'clsx'
import { FC, useEffect, useMemo, useState } from 'react'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'

import { useMobileNavigationContext } from '@/components/contexts'
import { BREAKPOINTS } from '@/constants'
import { useStore } from '@/store'
import { navigationItems } from '@/utils/data/navigationItems'

import {
  NavigationFooter,
  NavigationHeader,
  NavigationItem,
  PortalsDropdownList,
  PortalsDropdownListMobile
} from './components'
import styles from './Navigation.module.scss'

const Navigation: FC = () => {
  const { mobileNavigationOpened } = useMobileNavigationContext()

  const { width = 0 } = useWindowSize()
  const { selectedPortal } = useStore((store) => store.user)

  const [expandedItem, setExpandedItem] = useState<string | undefined>()
  const [collapsed, setCollapsed] = useLocalStorage('sidebarCollapsed', false)

  const isSmallView = width <= BREAKPOINTS.MD

  const items = useMemo(
    () =>
      selectedPortal?.id && selectedPortal.permissions
        ? navigationItems
            // Remove high level items that are not available
            .filter((item) =>
              item.visible(selectedPortal.type, selectedPortal.permissions)
            )
            .map((item) => {
              if (item.link) return item

              // Remove sub-items that are not available
              const availableSubItems = item.items?.filter((subItem) =>
                subItem.visible(selectedPortal.permissions)
              )

              return { ...item, items: availableSubItems || undefined }
            })
        : [],
    [selectedPortal]
  )

  const handleExpandItem = (id: string) => {
    setExpandedItem(id === expandedItem ? undefined : id)
  }

  const toggleCollapsed = () => setCollapsed((prev) => !prev)

  useEffect(() => {
    if (isSmallView && collapsed && mobileNavigationOpened) {
      setCollapsed(false)
    }
  }, [width, mobileNavigationOpened])

  const PortalPicker = isSmallView ? (
    <PortalsDropdownListMobile />
  ) : (
    <PortalsDropdownList collapsed={collapsed} />
  )

  return (
    <>
      {mobileNavigationOpened && <div className={styles.blackBackground} />}

      <nav
        className={clsx(
          styles.nav,
          collapsed && styles.collapsed,
          mobileNavigationOpened && styles.mobileNavigationOpened
        )}
      >
        <NavigationHeader collapsed={collapsed} />

        {!!selectedPortal?.id && PortalPicker}

        <div className={styles.navItems}>
          {items.map((item) => (
            <NavigationItem
              key={item.id}
              item={item}
              onExpand={handleExpandItem}
              sidebarCollapsed={collapsed}
              isExpanded={expandedItem === item.id}
            />
          ))}
        </div>

        <NavigationFooter
          collapsed={collapsed}
          toggleCollapsed={toggleCollapsed}
        />
      </nav>
    </>
  )
}

export default Navigation
