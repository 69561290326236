import clsx from 'clsx'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { Col, Text } from '@/components/atoms'
import { WithHeaderTemplate } from '@/components/templates'
import { selectPortal } from '@/features/auth/store'
import { IUserPortalsListItem } from '@/features/auth/types'
import { useNavigator } from '@/router'
import { useDispatch, useStore } from '@/store'
import { Color } from '@/styles/palette'
import { PortalTypes } from '@/types/enums/global'
import { FontWeight, TextAlign, TextTypes } from '@/types/enums/ui'
import { INavigationItem } from '@/types/interfaces/ui'
import { navigationItems } from '@/utils/data/navigationItems'
import { getAvailableLinksPerPortal, getInitials } from '@/utils/helpers'

import styles from './EnterpriseLanding.module.scss'

const portalAvatarsColors = [
  'background-color-yellow400 color-gray800',
  'background-color-blue300 color-gray0',
  'background-color-green500 color-gray0',
  'background-color-purple400 color-gray0'
]

const EnterpriseLanding = () => {
  const navigate = useNavigator()
  const dispatch = useDispatch()
  const { portals, selectedPortal, me } = useStore((store) => store.user)

  const { first_name = '' } = me || {}
  const { permissions = {} } = selectedPortal || {}

  const sitePortals = useMemo(
    () => portals.filter((portal) => portal.type !== PortalTypes.Enterprise),
    [portals]
  )

  const adminTools: INavigationItem[] = useMemo(
    () =>
      Object.entries(permissions)
        .filter(([, enabled]) => enabled)
        .map(([pageId]) => navigationItems.find((item) => item.id === pageId)!),
    [permissions]
  )

  const onSiteClick = async (sitePortal: IUserPortalsListItem) => {
    await dispatch(selectPortal(sitePortal.id))

    const availableLinks = getAvailableLinksPerPortal(sitePortal)

    navigate.to(availableLinks?.[0] || '/')
  }

  return (
    <WithHeaderTemplate
      title={`Hi ${first_name}!`}
      className={styles.landingPageWrapper}
    >
      <Col gap={40} className={styles.landingPageContent}>
        <Text type={TextTypes.DISPLAY_XS} color={Color.gray700}>
          Where would you like to go?
        </Text>

        <Col gap={16}>
          <Text
            type={TextTypes.TEXT_SM}
            weight={FontWeight.SEMIBOLD}
            color={Color.gray600}
          >
            Admin Tools
          </Text>

          <section className={styles.listItems}>
            {adminTools.map((item) => (
              <Link
                to={item.link!}
                key={item.id}
                className={styles.adminToolLink}
              >
                <Col items="center" justify="end" className={styles.card}>
                  {item.icon}

                  <Text
                    type={TextTypes.TEXT_SM}
                    weight={FontWeight.SEMIBOLD}
                    align={TextAlign.CENTER}
                    color={Color.gray700}
                    className={styles.cardTitle}
                  >
                    {item.title}
                  </Text>
                </Col>
              </Link>
            ))}
          </section>
        </Col>

        {sitePortals.length > 0 && (
          <Col gap={16}>
            <Text
              type={TextTypes.TEXT_SM}
              weight={FontWeight.SEMIBOLD}
              color={Color.gray600}
            >
              Site Portals
            </Text>

            <section className={styles.listItems}>
              {sitePortals.map((portal, index) => (
                <Col
                  key={portal.id}
                  items="center"
                  justify="end"
                  className={styles.card}
                  onClick={() => onSiteClick(portal)}
                >
                  <div
                    className={clsx(
                      styles.portalAvatar,
                      portalAvatarsColors[index % portalAvatarsColors.length]
                    )}
                  >
                    <Text type={TextTypes.TEXT_SM} weight={FontWeight.SEMIBOLD}>
                      {getInitials(portal.name)}
                    </Text>
                  </div>

                  <Text
                    type={TextTypes.TEXT_SM}
                    weight={FontWeight.SEMIBOLD}
                    align={TextAlign.CENTER}
                    color={Color.gray700}
                    className={styles.cardTitle}
                  >
                    {portal.name}
                  </Text>
                </Col>
              ))}
            </section>
          </Col>
        )}
      </Col>
    </WithHeaderTemplate>
  )
}

export default EnterpriseLanding
