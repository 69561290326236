const gateEndpoints = {
  GET_GATES: '/organizations/:org_id/sites/:site_id/gates',
  GET_LANES: '/organizations/:org_id/sites/:site_id/gates/:gate_id/lanes',
  GET_GATE_TRANSACTIONS:
    '/organizations/:org_id/sites/:site_id/gate-transactions',
  GET_DRIVERS: '/organizations/:org_id/sites/:site_id/drivers-onsite',
  GET_QUEUE_EVENTS: '/organizations/:org_id/lanes/:lane_id/events',
  GET_ACCOUNTS: '/organizations/:org_id/sites/:site_id/merchant-accounts',
  GET_PRESIGNED_URL: '/organizations/:org_id/sites/:site_id/presigned-url',

  CREATE_GATE_TRANSACTION:
    '/organizations/:org_id/sites/:site_id/gates/:gate_id/lanes/:lane_id/events'
}

export default gateEndpoints
