import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Chip } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  CargoDetailsReadOnly,
  DriverDetailsFormSection,
  DriverDetailsPowerUnitFormSection
} from '@/features/gate'
import { useFetchAccountsQuery } from '@/features/gate/api'
import { Driver } from '@/features/gate/types'
import { DriverDetailsSchema } from '@/features/gate/utils'
import { useStore } from '@/store'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { replaceNullToUndefined } from '@/utils/helpers'

interface IProps extends IModalWithCloseFn {
  item: Driver
}

const DriverDetailsModal: FC<IProps> = (props) => {
  const { item, closeModal } = props

  const { metadata } = item
  const { mismatch, appointment_type, thumbnail_path } = metadata

  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const formReturn = useForm({
    resolver: yupResolver(DriverDetailsSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',

    defaultValues: {
      ...replaceNullToUndefined(item),

      date: new Date(),
      appointmentType: appointment_type
    }
  })

  const { trigger } = formReturn

  const onSubmit = () => {
    trigger()
  }

  return (
    <Modal
      title="Driver Details"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      header={mismatch ? <Chip type="error" label="Mismatch" /> : undefined}
      footer={
        <Button action="submit" onClick={onSubmit} type="primary">
          Save Changes
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            thumbnail_path ? (
              <FormAside img={thumbnail_path} laneName="Lane 1" />
            ) : undefined
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            <FormSection title="Power Unit">
              <DriverDetailsPowerUnitFormSection accounts={accounts} />
            </FormSection>

            <FormSection title="Driver Details">
              <DriverDetailsFormSection />
            </FormSection>

            <FormSection title="Cargo Asset">
              <CargoDetailsReadOnly item={item} />
            </FormSection>
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default DriverDetailsModal
