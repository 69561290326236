import { useAuth0 } from '@auth0/auth0-react'
import { jwtDecode } from 'jwt-decode'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Loader } from '@/components/atoms'
import {
  useLazyCheckMyPermissionsQuery,
  useLazyFetchMySitesQuery
} from '@/features/auth/api'
import {
  OrganizationRelation,
  PermissionResourceType
} from '@/features/auth/enums'
import { preparePermissionResourceQuery } from '@/features/auth/helpers'
import { updatePortals, updateUserDetails } from '@/features/auth/store'
import { IJwt } from '@/features/auth/types'
import { useDispatch, useStore } from '@/store'

const ProtectedRouteTemplate: FC<PropsWithChildren> = (props) => {
  const { children } = props

  const [searchParams] = useSearchParams()
  const { selectedPortal } = useStore((s) => s.user)

  const [isFetchingProfileData, setFetchingProfileData] = useState(false)

  const [fetchMySites] = useLazyFetchMySitesQuery()
  const [checkPermission] = useLazyCheckMyPermissionsQuery()

  const dispatch = useDispatch()

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently
  } = useAuth0()

  const login = () => {
    const invitation = searchParams.get('invitation')
    const organization = searchParams.get('organization')

    const appState = {
      returnTo: `${window.location.pathname}${window.location.search}`
    }

    if (invitation && organization) {
      loginWithRedirect({
        authorizationParams: {
          organization,
          invitation
        },
        appState
      })

      return
    }

    loginWithRedirect({ appState })
  }

  const getUserInfo = async () => {
    try {
      setFetchingProfileData(() => true)

      const token = await getAccessTokenSilently({})
      const decodedJwt: IJwt = jwtDecode(token)

      const { org_id: orgId } = decodedJwt

      await dispatch(updateUserDetails(token))

      const sitesResponse = await fetchMySites({
        orgId
      })

      const orgPortalEnabled = await checkPermission({
        orgId,
        relation: OrganizationRelation.EnterprisePortal,
        resource: preparePermissionResourceQuery(
          PermissionResourceType.Organization,
          orgId
        )
      })

      await dispatch(
        updatePortals({
          sites: sitesResponse?.data?.data?.sites || [],
          enterpriseEnabled: !!orgPortalEnabled?.data?.data?.has_permission
        })
      )
    } catch {
      await dispatch(
        updatePortals({
          sites: [],
          enterpriseEnabled: false
        })
      )
    } finally {
      setFetchingProfileData(() => false)
    }
  }

  useEffect(() => {
    if (isLoading) return

    if (isAuthenticated) {
      getUserInfo()
      return
    }

    login()
  }, [isAuthenticated, isLoading])

  // If user has access to any portal we need to wait for the roles to be fetched
  if (
    !isAuthenticated ||
    isFetchingProfileData ||
    // Even if user has no access no any portals we set "null" value instead of "undefined"
    selectedPortal === undefined
  ) {
    return <Loader fullScreen />
  }

  return <>{children}</>
}

export default ProtectedRouteTemplate
