import { FC, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Checkbox, Col, Input, Row, Select } from '@/components/atoms'
import { SuggestedPowerUnitID } from '@/features/gate'
import {
  CheckInSchema,
  isAssetEmpty,
  isCargoAssetMinimized,
  isChassisIdVisible
} from '@/features/gate/utils'
import { statesOptions } from '@/utils/data'
import {
  cargoAssetTypeSelectOptions,
  carriersSelectOptions,
  loadStatusSelectOptions
} from '@/utils/mappers'

interface IProps {
  suggestedIds: string[]
}

const CheckInCargoAssetFormSection: FC<IProps> = (props) => {
  const { suggestedIds } = props

  const [selectedSuggestedId, setSelectedSuggestedId] = useState<
    string | undefined
  >()

  const {
    watch,
    register,
    setValue,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckInSchema>>()

  const puType = watch('power_unit_type')
  const assetType = watch('cargo_asset_asset_type')
  const loadStatus = watch('load_status')
  const cargoAssetId = watch('cargo_asset_owner_id')

  const sealMatches = watch('seal_matchPW')

  const isMinimized = isCargoAssetMinimized(puType)
  const isChassisVisible = isChassisIdVisible(puType, assetType)
  const isEmpty = isAssetEmpty(loadStatus)

  const onSelectSuggestedItem = (value: string) => {
    setSelectedSuggestedId(value)
    setValue('cargo_asset_owner_id', value, { shouldValidate: true })
  }

  useEffect(() => {
    if (cargoAssetId !== selectedSuggestedId) {
      setSelectedSuggestedId(undefined)
    }
  }, [cargoAssetId])

  return (
    <Col gap={20}>
      {!isMinimized && (
        <>
          <Controller
            name="cargo_asset_asset_type"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="Asset Type"
                value={field.value}
                error={!!formState.errors.cargo_asset_asset_type}
                helperText={formState.errors.cargo_asset_asset_type?.message}
                options={cargoAssetTypeSelectOptions}
                onChange={field.onChange}
              />
            )}
          />

          <Col gap={7}>
            <Row gap={16}>
              <Input
                {...register('cargo_asset_owner_id')}
                required
                uppercase
                fullWidth
                label="Asset ID"
                error={!!errors.cargo_asset_owner_id}
                helperText={errors.cargo_asset_owner_id?.message}
              />

              <Controller
                name="cargo_asset_carrier_name"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    required
                    label="Asset Carrier"
                    value={field.value}
                    options={carriersSelectOptions}
                    error={!!formState.errors.cargo_asset_carrier_name}
                    helperText={
                      formState.errors.cargo_asset_carrier_name?.message
                    }
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>

            {suggestedIds.length > 1 && (
              <SuggestedPowerUnitID
                suggestedItems={suggestedIds || []}
                selectedId={selectedSuggestedId}
                onChange={onSelectSuggestedItem}
              />
            )}
          </Col>
        </>
      )}

      <Row gap={16}>
        <Controller
          name="load_status"
          control={control}
          render={({ field, formState }) => (
            <Select
              label="Load Status"
              value={field.value}
              options={loadStatusSelectOptions}
              error={!!formState.errors.load_status}
              helperText={formState.errors.load_status?.message}
              onChange={field.onChange}
            />
          )}
        />

        {!isEmpty && (
          <Input
            {...register('shipment_number')}
            uppercase
            fullWidth
            label="Inbound Ref #"
            error={!!errors.shipment_number}
            helperText={errors.shipment_number?.message}
          />
        )}
      </Row>

      {!isEmpty && (
        <Col items="stretch" gap={4}>
          <Controller
            name="seal_numbers"
            control={control}
            render={({ field, fieldState }) => (
              <Input
                fullWidth
                type="multiple"
                label="Seal #"
                value={field.value}
                onChange={field.onChange}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />

          <Checkbox
            value={sealMatches}
            label="The seal matches the paperwork "
            onChange={() => setValue('seal_matchPW', !sealMatches)}
          />
        </Col>
      )}

      {isChassisVisible && (
        <Input
          {...register('chassis_id')}
          uppercase
          fullWidth
          label="Chassis ID"
          error={!!errors.chassis_id}
          helperText={errors.chassis_id?.message}
        />
      )}

      {!isMinimized && (
        <Row gap={16}>
          <Input
            {...register('cargo_asset_license_plate_number')}
            uppercase
            fullWidth
            label="LPN"
            error={!!errors.cargo_asset_license_plate_number}
            helperText={errors.cargo_asset_license_plate_number?.message}
          />

          <Controller
            name="cargo_asset_license_plate_state"
            control={control}
            render={({ field, formState }) => (
              <Select
                label="LPN State/Region"
                value={field.value}
                options={statesOptions}
                error={!!formState.errors.cargo_asset_license_plate_state}
                helperText={
                  formState.errors.cargo_asset_license_plate_state?.message
                }
                onChange={field.onChange}
              />
            )}
          />
        </Row>
      )}
    </Col>
  )
}

export default CheckInCargoAssetFormSection
