import { baseApi, withParams } from '@/api'

import endpoints from './endpoints'
import {
  FetchCargoAssetsRequest,
  FetchCargoAssetsResponse
} from '@/features/yard/api/types'

export const yardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchCargoAssets: builder.query<
      FetchCargoAssetsResponse,
      FetchCargoAssetsRequest
    >({
      query: ({ org_id, site_id, ...params }) => ({
        url: withParams(endpoints.GET_CARGO_ASSETS, { org_id, site_id }),
        params
      })
    })
  })
})

export const { useFetchCargoAssetsQuery } = yardApi
