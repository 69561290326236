import { yupResolver } from '@hookform/resolvers/yup'
import { isEqual } from 'lodash'
import React, { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Button, Col, Loader, Snackbar } from '@/components/atoms'
import { Modal } from '@/components/organisms'
import {
  PortalsAndRolesFormSection,
  ProfileDetailsFormSection
} from '@/features/user-management'
import {
  useInviteUserMutation,
  useLazyFetchUserOrgRolesQuery,
  useLazyFetchUserSiteRolesQuery,
  useLazyFetchUserSitesQuery,
  useUpdateOrgRolesMutation,
  useUpdateSiteRolesMutation,
  useUpdateUserDetailsMutation
} from '@/features/user-management/api'
import {
  ISite,
  ISiteRoles,
  ISiteRolesResponseData
} from '@/features/user-management/api/types'
import { IUser } from '@/features/user-management/types'
import { EditUserSchema } from '@/features/user-management/utils'
import { useStore } from '@/store'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { generateRandomId } from '@/utils/helpers'

interface IProps extends IModalWithCloseFn {
  editMode?: boolean
  selectedItem?: IUser
  onInviteCallback: () => void
  onEditCallback: () => void
}

const AddEditUserModal: FC<IProps> = (props) => {
  const {
    closeModal,
    selectedItem,
    onEditCallback,
    onInviteCallback,
    editMode = false
  } = props

  const { org, me } = useStore((store) => store.user)

  const { organization_id } = org || {}
  const { user_id } = me || {}

  const [sites, setSites] = useState<ISite[]>([])
  // We need to save all sites roles in 1 global state to map through them when editing user
  const [userCurrentSiteRoles, setUserCurrentSiteRoles] = useState<
    ISiteRolesResponseData[]
  >([])
  const [errorMsg, setErrorMsg] = useState<string | null>(null)

  const [inviteUser, inviteUserStatus] = useInviteUserMutation()
  const [updateUserProfile, updateUserProfileStatus] =
    useUpdateUserDetailsMutation()
  const [updateOrgRoles, updateOrgRolesStatus] = useUpdateOrgRolesMutation()
  const [updateSiteRoles, updateSiteRolesStatus] = useUpdateSiteRolesMutation()

  const [fetchOrgRoles, fetchOrgRolesStatus] = useLazyFetchUserOrgRolesQuery()
  const [fetchSites, fetchSitesStatus] = useLazyFetchUserSitesQuery()
  const [fetchSiteRoles, fetchSiteRolesStatus] =
    useLazyFetchUserSiteRolesQuery()

  const formReturn = useForm({
    resolver: yupResolver(EditUserSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      first_name: selectedItem?.first_name,
      last_name: selectedItem?.last_name,
      email: selectedItem?.email,

      enterpriseEnabled: false,
      siteEnabled: false,
      roles: [],
      site_roles: []
    }
  })
  const { trigger, setValue, getValues } = formReturn

  const btnText = editMode ? 'Save User' : 'Send Invite'

  const isFetching =
    fetchOrgRolesStatus.isFetching ||
    fetchSitesStatus.isFetching ||
    fetchSiteRolesStatus.isFetching

  const isSubmitting =
    inviteUserStatus.isLoading ||
    updateUserProfileStatus.isLoading ||
    updateOrgRolesStatus.isLoading ||
    updateSiteRolesStatus.isLoading

  const onSubmit = async () => {
    const valid = await trigger()

    if (!valid || !organization_id) return

    const {
      email,
      first_name,
      last_name,
      roles = [],
      site_roles = [],
      enterpriseEnabled,
      siteEnabled
    } = getValues()

    const siteRoles = site_roles.map((item: ISiteRoles) => ({
      site_id: item.site_id,
      roles: item.roles
    }))

    try {
      if (editMode && selectedItem) {
        if (
          first_name !== selectedItem?.first_name ||
          last_name !== selectedItem?.last_name
        ) {
          await updateUserProfile({
            userId: selectedItem.id,
            orgId: organization_id,
            first_name,
            last_name,
            display_name: `${first_name} ${last_name}`
          }).unwrap()
        }

        const orgRoles = enterpriseEnabled ? roles : []

        if (!isEqual(orgRoles, fetchOrgRolesStatus?.data?.data?.roles)) {
          await updateOrgRoles({
            userId: selectedItem.id,
            orgId: organization_id,
            roles: orgRoles
          }).unwrap()
        }

        const siteRolesToUpdate: ISiteRolesResponseData[] = []

        // Remove old site roles if site portal is disabled
        if (!siteEnabled && userCurrentSiteRoles.length) {
          siteRolesToUpdate.push(
            ...userCurrentSiteRoles.map((item) => ({
              site_id: item.site_id,
              roles: []
            }))
          )
        }

        // Save new roles for sites if user didn't have any before
        if (siteEnabled && !userCurrentSiteRoles.length) {
          siteRolesToUpdate.push(
            ...siteRoles.map((item) => ({
              site_id: item.site_id,
              roles: item.roles
            }))
          )
        }

        // Update roles for both old and new sites
        if (siteEnabled && userCurrentSiteRoles.length) {
          // If user had roles for site but now they are removed we need to remove them from DB
          const siteRolesToRemove = userCurrentSiteRoles.filter(
            (site) =>
              !siteRoles.find((newSite) => newSite.site_id === site.site_id)
          )

          siteRolesToUpdate.push(
            ...siteRolesToRemove.map((item) => ({
              site_id: item.site_id,
              roles: []
            }))
          )

          siteRolesToUpdate.push(
            ...siteRoles.map((item) => ({
              site_id: item.site_id,
              roles: item.roles
            }))
          )
        }

        await Promise.all(
          siteRolesToUpdate.map((item) =>
            updateSiteRoles({
              userId: selectedItem.id,
              orgId: organization_id,
              siteId: item.site_id,
              roles: item.roles
            }).unwrap()
          )
        )

        onEditCallback()
        return
      }

      await inviteUser({
        orgId: organization_id,
        invitee: {
          first_name,
          last_name,
          email,
          roles: enterpriseEnabled ? roles : [],
          site_roles: siteEnabled ? siteRoles : []
        }
      })

      onInviteCallback()
    } catch (e: any) {
      setErrorMsg(e?.data?.errors?.[0]?.message || 'Something went wrong')
    }
  }

  const getOrgRoles = async () => {
    if (!selectedItem || !organization_id) return

    try {
      const response = await fetchOrgRoles({
        orgId: organization_id,
        userId: selectedItem.id
      })

      setValue('roles', response?.data?.data?.roles || [])
      setValue('enterpriseEnabled', !!response?.data?.data?.roles?.length)
    } catch {
      setValue('roles', [])
      setValue('enterpriseEnabled', false)
    }
  }

  const getAvailableSites = async () => {
    if (!user_id || !organization_id) return

    try {
      const response = await fetchSites({
        orgId: organization_id,
        userId: user_id
      })

      setSites(response?.data?.data?.sites || [])
    } catch {
      setSites([])
    }
  }

  const getUserSites = async () => {
    if (!selectedItem?.id || !organization_id) return

    try {
      const sitesResponse = await fetchSites({
        orgId: organization_id,
        userId: selectedItem?.id
      })

      const userSites = sitesResponse?.data?.data?.sites || []

      if (!userSites.length) return

      const userSitesRoles = await Promise.all(
        userSites.map((site) =>
          fetchSiteRoles({
            orgId: organization_id,
            userId: selectedItem.id,
            siteId: site.id
          })
        )
      )

      const site_roles = userSitesRoles.reduce((list: ISiteRoles[], item) => {
        if (!item?.data?.data?.roles?.length) return list

        list.push({
          id: generateRandomId(),
          site_id: item.data.data.site_id,
          roles: item.data.data.roles
        })

        return list
      }, [])

      setValue('site_roles', site_roles)
      setValue('siteEnabled', !!site_roles.length)
      setUserCurrentSiteRoles(
        site_roles.map((item) => ({ site_id: item.site_id, roles: item.roles }))
      )
    } catch {
      setValue('site_roles', [])
      setValue('siteEnabled', false)
      setUserCurrentSiteRoles([])
    }
  }

  useEffect(() => {
    if (selectedItem && organization_id) {
      getOrgRoles()
      getUserSites()
    }
  }, [organization_id])

  useEffect(() => {
    getAvailableSites()
  }, [])

  return (
    <Modal
      closeModal={closeModal}
      placement="right"
      className="tw-w-[536px]"
      title={editMode ? 'Edit User' : 'Invite User'}
      cancelButtonText="Cancel"
      footer={
        <Button
          action="submit"
          type="primary"
          onClick={onSubmit}
          disabled={isSubmitting || isFetching}
        >
          {isSubmitting ? 'Saving...' : btnText}
        </Button>
      }
    >
      <Snackbar
        type="error"
        open={!!errorMsg}
        onClose={() => setErrorMsg(null)}
        message={errorMsg}
      />

      <FormProvider {...formReturn}>
        <ProfileDetailsFormSection editMode={editMode} />

        {isFetching ? (
          <Col
            items="center"
            justify="center"
            className="tw-flex-1 tw-min-h-[200px]"
          >
            <Loader />
          </Col>
        ) : (
          <PortalsAndRolesFormSection sites={sites} />
        )}
      </FormProvider>
    </Modal>
  )
}

export default AddEditUserModal
