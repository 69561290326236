import { yupResolver } from '@hookform/resolvers/yup'
import { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Button } from '@/components/atoms'
import {
  FormAside,
  FormSection,
  GeneralFormSection,
  TwoColumnModalWrapper
} from '@/components/molecules'
import { Modal } from '@/components/organisms'
import {
  CheckOutCargoAssetFormSection,
  CheckOutPowerUnitFormSection,
  DriverDetailsFormSection
} from '@/features/gate'
import {
  useCreateGateTransactionMutation,
  useFetchAccountsQuery
} from '@/features/gate/api'
import {
  CreateGateTransactionMetadata,
  CreateGateTransactionRequestBody,
  CreateGateTransactionRequestParams
} from '@/features/gate/api/types'
import {
  DomainEventSchema,
  DomainEventTypes,
  DomainTransactionTypes,
  SealMatchesPW
} from '@/features/gate/enums'
import { GateQueueEvent, Lane } from '@/features/gate/types'
import {
  CheckOutSchema,
  getSealMatchesPWValue,
  isAssetEmpty,
  isCargoAssetMinimized,
  isCargoAssetVisible,
  isChassisIdVisible
} from '@/features/gate/utils'
import { useStore } from '@/store'
import {
  AppointmentTypes,
  CargoAssetTypes,
  FuelTypes,
  LoadTypes,
  PowerUnitTypes,
  WeightClasses
} from '@/types/enums/transactionDetails'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { formatPhoneNumber, prepareSealNumbers } from '@/utils/helpers'
import { emissionTypeByFuelMapper } from '@/utils/mappers'

interface IProps extends IModalWithCloseFn {
  item?: GateQueueEvent
  lane: Lane
  gateId: string
}

const CheckOutModal: FC<IProps> = (props) => {
  const { item, gateId, lane, closeModal } = props

  const { selectedPortal, org } = useStore((store) => store.user)

  const org_id = org?.organization_id || ''
  const site_id = selectedPortal?.id || ''

  const [createGateTransaction, { isLoading }] =
    useCreateGateTransactionMutation()
  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const formReturn = useForm<yup.InferType<typeof CheckOutSchema>>({
    resolver: yupResolver(CheckOutSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
    defaultValues: {
      id: item?.id,

      date: new Date(),
      appointment_type: AppointmentTypes.DROP,

      power_unit_owner_id: item?.metadata?.power_unit_owner_id?.[0] || '',
      power_unit_license_plate_number:
        item?.metadata?.power_unit_license_plate_number?.[0] || '',
      power_unit_license_plate_state:
        item?.metadata?.power_unit_license_plate_state?.[0] || '',
      power_unit_carrier_usdot:
        item?.metadata?.power_unit_carrier_usdot?.[0] || '',
      power_unit_weight_class: (item?.metadata?.power_unit_weight_class ||
        '') as WeightClasses,
      power_unit_vin: item?.metadata?.power_unit_vin?.[0] || '',
      power_unit_fuel_type: (item?.metadata?.power_unit_fuel_type ||
        '') as FuelTypes,
      power_unit_carrier_name: item?.metadata?.power_unit_carrier_name || '',
      account_name: item?.metadata?.account_names || [],

      driver_first_name: item?.metadata?.driver_first_name || '',
      driver_last_name: item?.metadata?.driver_last_name || '',
      driver_license_number: item?.metadata?.driver_license_number || '',
      driver_phone_number: formatPhoneNumber(
        item?.metadata?.driver_phone_number
      ),
      driver_license_state: item?.metadata?.driver_license_state || '',

      cargo_asset_owner_id:
        item?.metadata?.cargo_asset_owner_id?.[0] || undefined,
      cargo_asset_license_plate_number:
        item?.metadata?.cargo_asset_license_plate_number?.[0] || undefined,
      cargo_asset_license_plate_state:
        item?.metadata?.cargo_asset_license_plate_state?.[0] || undefined,
      cargo_asset_carrier_name:
        item?.metadata?.cargo_asset_carrier_name || undefined,
      cargo_asset_asset_type:
        item?.metadata?.cargo_asset_asset_type?.[0] === 'TRAILER'
          ? CargoAssetTypes.Trailer
          : undefined,
      chassis_id: item?.metadata?.chassis_id || undefined,
      shipment_number: item?.metadata?.shipment_number || undefined,
      seal_numbers: item?.metadata?.seal_numbers || []
    }
  })

  const { watch, trigger, getValues } = formReturn

  const showCargoAsset = isCargoAssetVisible(
    watch('appointment_type'),
    watch('power_unit_type'),
    false
  )
  const isManually = !item

  const onSubmit = async () => {
    const valid = await trigger()

    if (!valid || !org_id || !site_id) return

    const {
      appointment_type,

      power_unit_type,
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state,
      power_unit_carrier_usdot,
      power_unit_weight_class,
      power_unit_fuel_type,
      power_unit_carrier_name,
      power_unit_vin,
      account_name,

      driver_first_name,
      driver_last_name,
      driver_license_number,
      driver_phone_number,
      driver_license_state,

      cargo_asset_owner_id,
      cargo_asset_license_plate_number,
      cargo_asset_license_plate_state,
      cargo_asset_carrier_name,
      cargo_asset_asset_type,
      load_status,
      shipment_number,
      seal_numbers,
      chassis_id,
      seal_matchPW
    } = getValues()

    const metadata: CreateGateTransactionMetadata = {
      thumbnail_path: '',

      lane_id: lane.id,
      transaction_type: DomainTransactionTypes.CheckOut,
      appointment_type,

      power_unit_type: power_unit_type || null,
      power_unit_owner_id,
      power_unit_license_plate_number,
      power_unit_license_plate_state: power_unit_license_plate_state || null,

      power_unit_carrier_usdot: power_unit_carrier_usdot || null,
      power_unit_carrier_name: power_unit_carrier_name || null,
      power_unit_carrier_MCNum:
        item?.metadata?.power_unit_carrier_MCNum?.[0] || '',

      power_unit_vin: power_unit_vin || null,
      power_unit_weight_class: power_unit_weight_class || null,
      power_unit_fuel_type: power_unit_fuel_type || null,
      power_unit_emission_type: power_unit_fuel_type
        ? emissionTypeByFuelMapper[power_unit_fuel_type]
        : null,
      account_name: account_name || [],

      driver_first_name: driver_first_name || null,
      driver_last_name: driver_last_name || null,
      driver_license_number: driver_license_number || null,
      driver_phone_number: driver_phone_number || null,
      driver_license_state: driver_license_state || null,

      cargo_asset_owner_id: null,
      cargo_asset_license_plate_number: null,
      cargo_asset_license_plate_state: null,
      cargo_asset_carrier_name: null,
      cargo_asset_asset_type: null,
      load_status: null,
      shipment_number: null,
      seal_numbers: [],
      seal_matchPW: SealMatchesPW.NoSeal,
      chassis_id: null,

      mismatch: false
    }

    if (showCargoAsset) {
      metadata.load_status = load_status as LoadTypes

      if (!isCargoAssetMinimized(power_unit_type as PowerUnitTypes)) {
        metadata.cargo_asset_license_plate_number =
          cargo_asset_license_plate_number as string
        metadata.cargo_asset_license_plate_state =
          cargo_asset_license_plate_state as string
        metadata.cargo_asset_owner_id = cargo_asset_owner_id as string
        metadata.cargo_asset_carrier_name = cargo_asset_carrier_name || null
        metadata.cargo_asset_asset_type = cargo_asset_asset_type || null
      }

      if (!isAssetEmpty(load_status as LoadTypes)) {
        metadata.shipment_number = shipment_number || null
        metadata.seal_numbers = prepareSealNumbers(seal_numbers)
        metadata.seal_matchPW = getSealMatchesPWValue(
          seal_matchPW,
          seal_numbers
        )
      }

      if (
        isChassisIdVisible(
          power_unit_type as PowerUnitTypes,
          cargo_asset_asset_type as CargoAssetTypes
        )
      ) {
        metadata.chassis_id = chassis_id || null
      }
    }

    const query: CreateGateTransactionRequestBody = {
      reference_id: item?.correlation_id || '',
      transaction_type: DomainTransactionTypes.CheckOut,
      type: DomainEventTypes.ManualCheckOut,
      schema: DomainEventSchema.September2024,
      metadata,
      metadata_raw: item?.metadata?.AssetChainEvent || {}
    }

    const params: CreateGateTransactionRequestParams = {
      org_id,
      site_id,
      gate_id: gateId,
      lane_id: lane.id
    }

    try {
      await createGateTransaction({
        params,
        body: query
      }).unwrap()

      closeModal()
    } catch (e) {
      // TODO: Show snackbar with error
    }
  }

  return (
    <Modal
      title="Check-Out"
      placement="fullScreen"
      closeModal={closeModal}
      bodyClassName="tw-flex-1 !tw-p-0"
      headerClassName="!tw-bg-transparent"
      footer={
        <Button
          action="submit"
          disabled={isLoading}
          onClick={onSubmit}
          type="primary"
        >
          {isLoading ? 'Loading...' : 'Complete Check-Out'}
        </Button>
      }
    >
      <FormProvider {...formReturn}>
        <TwoColumnModalWrapper
          leftSide={
            item ? (
              <FormAside
                img={item.metadata.snapshot_url}
                laneName={lane.display_name}
              />
            ) : undefined
          }
        >
          <form>
            <FormSection title="General">
              <GeneralFormSection />
            </FormSection>

            <FormSection title="Power Unit">
              <CheckOutPowerUnitFormSection
                accounts={accounts}
                powerUnitIds={item?.metadata?.power_unit_owner_id || []}
                powerUnitLPNs={
                  item?.metadata?.power_unit_license_plate_number || []
                }
              />
            </FormSection>

            <FormSection title="Driver Details">
              <DriverDetailsFormSection readOnly={!isManually} />
            </FormSection>

            {showCargoAsset && (
              <FormSection title="Cargo Asset">
                <CheckOutCargoAssetFormSection
                  cargoAssetIds={item?.metadata?.cargo_asset_owner_id || []}
                  cargoAssetLPNs={
                    item?.metadata?.cargo_asset_license_plate_number || []
                  }
                />
              </FormSection>
            )}
          </form>
        </TwoColumnModalWrapper>
      </FormProvider>
    </Modal>
  )
}

export default CheckOutModal
