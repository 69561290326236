import IosShareIcon from '@mui/icons-material/IosShare'
import { useState } from 'react'

import { Button, HeaderActionPortal } from '@/components/atoms'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { DriverDetailsModal } from '@/features/gate'
import { useFetchGateDriversQuery } from '@/features/gate/api'
import { Driver } from '@/features/gate/types'
import { driversColumns, driversFilters } from '@/features/gate/utils'
import { useModal } from '@/hooks'
import { useStore } from '@/store'
import { TableActions } from '@/types/enums/table'
import { PaginationQuery, SearchQuery } from '@/types/interfaces/api'

const GateDriversTemplate = () => {
  const { selectedPortal, org } = useStore((store) => store.user)
  const { visible, openModal, closeModal } = useModal(false)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [{ current_page, items_per_page, search_query }, setQuery] = useState<
    PaginationQuery & SearchQuery
  >({
    current_page: 1,
    items_per_page: PAGINATION_ITEMS_PER_PAGE,
    search_query: ''
  })
  const [selectedItem, setSelectedItem] = useState<Driver | undefined>()

  const { data, isFetching } = useFetchGateDriversQuery(
    {
      org_id,
      site_id,
      current_page,
      items_per_page,
      search_query
    },
    { skip: !org_id || !site_id }
  )

  const handleAction = (action: TableActions, row: any) => {
    switch (action) {
      case TableActions.RowClick: {
        openModal()
        setSelectedItem(row)
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {visible && (
        <DriverDetailsModal
          item={selectedItem as Driver}
          closeModal={closeModal}
        />
      )}

      <WithHeaderTemplate title="Drivers On Site">
        <HeaderActionPortal>
          <Button type="outlined" startIcon={<IosShareIcon />}>
            Export
          </Button>
        </HeaderActionPortal>

        <div className="tw-flex-1 tw-flex">
          <Table
            clickable
            name="drivers"
            idAccessor="id"
            rows={data?.data?.drivers || []}
            loading={isFetching}
            columns={driversColumns}
            handleAction={handleAction}
            filters={driversFilters}
            searchPlaceholder="Search Visitor"
            perPage={items_per_page}
            currentPage={current_page}
            totalPages={data?.pagination?.total_pages}
            totalItems={data?.pagination?.total_items_count}
            onPageChange={(page) =>
              setQuery((prev) => ({ ...prev, current_page: page }))
            }
            onSearch={(value) => {
              setQuery((prev) => ({ ...prev, search_query: value }))
            }}
          />
        </div>
      </WithHeaderTemplate>
    </>
  )
}

export default GateDriversTemplate
