import { Collapse } from '@mui/material'
import clsx from 'clsx'
import { FC, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Col, Input, ReadOnlyFormValue, Row, Select } from '@/components/atoms'
import { ToggleDetailsButton } from '@/components/molecules'
import { CheckOutSchema } from '@/features/gate/utils'
import { ISelectOption } from '@/types/interfaces/ui'
import { statesOptions } from '@/utils/data'
import {
  carriersSelectOptions,
  classListSelectOptions,
  fuelTypeSelectOptions,
  fuelTypesFriendlyNames,
  powerUnitTypeSelectOptions,
  powerUnitTypesFriendlyNames,
  weightClassesFriendlyNames
} from '@/utils/mappers'
import { userFriendlyState } from '@/utils/table'

interface IProps {
  accounts: ISelectOption[]
  powerUnitIds: string[]
  powerUnitLPNs: string[]
}

const CheckOutPowerUnitFormSection: FC<IProps> = (props) => {
  const { accounts, powerUnitIds, powerUnitLPNs } = props

  const {
    watch,
    register,
    control,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof CheckOutSchema>>()

  const isManually = !watch('id')

  const [showReadOnlyFields, setShowReadOnlyFields] = useState(false)
  const [animationInProgress, setAnimationInProgress] = useState(false)

  const puLPNState = watch('power_unit_license_plate_state')
  const puType = watch('power_unit_type')
  const usDOT = watch('power_unit_carrier_usdot')
  const operatingCarrier = watch('power_unit_carrier_name')
  const puClass = watch('power_unit_weight_class')
  const puFuelType = watch('power_unit_fuel_type')
  const puVIN = watch('power_unit_vin')
  const account = watch('account_name')

  const toggleDetails = () => {
    setShowReadOnlyFields(!showReadOnlyFields)

    if (showReadOnlyFields) {
      setAnimationInProgress(true)
    }
  }

  return (
    <Col items="stretch" gap={20}>
      {isManually ? (
        <Controller
          name="power_unit_type"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="Type"
              value={field.value}
              error={!!formState.errors.power_unit_type}
              helperText={formState.errors.power_unit_type?.message}
              options={powerUnitTypeSelectOptions}
              onChange={field.onChange}
            />
          )}
        />
      ) : (
        <ReadOnlyFormValue
          title="Type"
          value={puType ? powerUnitTypesFriendlyNames[puType] : undefined}
        />
      )}

      {isManually ? (
        <Input
          required
          uppercase
          fullWidth
          {...register('power_unit_owner_id')}
          label="ID"
          error={!!errors.power_unit_owner_id}
          helperText={errors.power_unit_owner_id?.message}
        />
      ) : (
        <Controller
          name="power_unit_owner_id"
          control={control}
          render={({ field, formState }) => (
            <Select
              required
              label="ID"
              value={field.value}
              error={!!formState.errors.power_unit_owner_id}
              helperText={formState.errors.power_unit_owner_id?.message}
              options={powerUnitIds.map((id) => ({
                id,
                label: id
              }))}
              onChange={field.onChange}
            />
          )}
        />
      )}

      {isManually ? (
        <Row gap={16}>
          <Input
            {...register('power_unit_license_plate_number')}
            required
            uppercase
            fullWidth
            label="LPN"
            error={!!errors.power_unit_license_plate_number}
            helperText={errors.power_unit_license_plate_number?.message}
          />

          <Controller
            name="power_unit_license_plate_state"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="LPN State/Region"
                value={field.value}
                options={statesOptions}
                error={!!formState.errors.power_unit_license_plate_state}
                helperText={
                  formState.errors.power_unit_license_plate_state?.message
                }
                onChange={field.onChange}
              />
            )}
          />
        </Row>
      ) : (
        <Row gap={16}>
          <Controller
            name="power_unit_license_plate_number"
            control={control}
            render={({ field, formState }) => (
              <Select
                required
                label="LPN"
                value={field.value}
                error={!!formState.errors.power_unit_license_plate_number}
                helperText={
                  formState.errors.power_unit_license_plate_number?.message
                }
                options={powerUnitLPNs.map((lpn) => ({
                  id: lpn,
                  label: lpn
                }))}
                onChange={field.onChange}
              />
            )}
          />

          <ReadOnlyFormValue
            title="LPN State/Region"
            value={
              puLPNState
                ? userFriendlyState({ puLPNState }, 'puLPNState')
                : undefined
            }
          />
        </Row>
      )}

      <Collapse
        in={showReadOnlyFields || isManually}
        unmountOnExit
        onExited={() => setAnimationInProgress(false)}
        className={clsx(
          animationInProgress && '!tw-mt-[-20px] tw-relative tw-top-[20px]'
        )}
      >
        <Col gap={20}>
          {isManually ? (
            <Row gap={16}>
              <Input
                {...register('power_unit_carrier_usdot')}
                required
                fullWidth
                digitsOnly
                type="number"
                label="US DOT #"
                error={!!errors.power_unit_carrier_usdot}
                helperText={errors.power_unit_carrier_usdot?.message}
              />

              <Controller
                name="power_unit_carrier_name"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    required
                    label="Carrier"
                    value={field.value}
                    options={carriersSelectOptions}
                    error={!!formState.errors.power_unit_carrier_name}
                    helperText={
                      formState.errors.power_unit_carrier_name?.message
                    }
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
          ) : (
            <Row gap={16}>
              <ReadOnlyFormValue title="US DOT #" value={usDOT} />
              <ReadOnlyFormValue title="Carrier" value={operatingCarrier} />
            </Row>
          )}

          {isManually ? (
            <Row gap={16}>
              <Controller
                name="power_unit_weight_class"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    required
                    label="Class"
                    value={field.value}
                    options={classListSelectOptions}
                    error={!!formState.errors.power_unit_weight_class}
                    helperText={
                      formState.errors.power_unit_weight_class?.message
                    }
                    onChange={field.onChange}
                  />
                )}
              />

              <Controller
                name="power_unit_fuel_type"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    required
                    label="Fuel Type"
                    value={field.value}
                    options={fuelTypeSelectOptions}
                    error={!!formState.errors.power_unit_fuel_type}
                    helperText={formState.errors.power_unit_fuel_type?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
          ) : (
            <Row gap={16}>
              <ReadOnlyFormValue
                title="Class"
                value={
                  puClass ? weightClassesFriendlyNames[puClass] : undefined
                }
              />
              <ReadOnlyFormValue
                title="Fuel Type"
                value={
                  puFuelType ? fuelTypesFriendlyNames[puFuelType] : undefined
                }
              />
            </Row>
          )}

          {isManually ? (
            <Row gap={16}>
              <Input
                {...register('power_unit_vin')}
                uppercase
                fullWidth
                label="VIN"
                error={!!errors.power_unit_vin}
                helperText={errors.power_unit_vin?.message}
              />

              <Controller
                name="account_name"
                control={control}
                render={({ field, formState }) => (
                  <Select
                    multiple
                    label="Account"
                    value={field.value}
                    options={accounts}
                    error={!!formState.errors.account_name}
                    helperText={formState.errors.account_name?.message}
                    onChange={field.onChange}
                  />
                )}
              />
            </Row>
          ) : (
            <Row gap={16}>
              <ReadOnlyFormValue title="VIN" value={puVIN} />
              <ReadOnlyFormValue title="Account" value={account?.toString()} />
            </Row>
          )}
        </Col>
      </Collapse>

      {!isManually && (
        <ToggleDetailsButton
          showDetails={showReadOnlyFields}
          className="tw-self-start !tw-px-0"
          onClick={toggleDetails}
        />
      )}
    </Col>
  )
}

export default CheckOutPowerUnitFormSection
