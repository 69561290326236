import * as Sentry from '@sentry/react'
import { useEffect, useMemo, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useMobileNavigationContext } from '@/components/contexts'
import { Navigation } from '@/components/organisms'
import { ErrorTemplate } from '@/components/templates'
import { ROUTES, useNavigator } from '@/router'
import { useStore } from '@/store'
import { PortalTypes } from '@/types/enums/global'
import { ErrorType } from '@/types/enums/ui'
import { getAvailableLinksPerPortal } from '@/utils/helpers'

import styles from './MainTemplate.module.scss'

const MainTemplate = () => {
  const location = useLocation()
  const navigate = useNavigator()

  const path = location.pathname

  const { selectedPortal } = useStore((store) => store.user)
  const { toggleMobileNavigation } = useMobileNavigationContext()

  const prevPortalRef = useRef<string | undefined>(selectedPortal?.id)

  const allowedLinks: string[] = useMemo(
    () => getAvailableLinksPerPortal(selectedPortal),
    [selectedPortal]
  )

  useEffect(() => {
    toggleMobileNavigation(false)

    const isPortalChanged = prevPortalRef.current !== selectedPortal?.id

    // No need to auto redirect if user is on profile page and portal is not changed
    if (location.pathname === ROUTES.PROFILE && !isPortalChanged) return

    prevPortalRef.current = selectedPortal?.id

    if (!allowedLinks.includes(location.pathname)) {
      const redirectTo = allowedLinks?.length ? allowedLinks[0] : ROUTES.HOME

      // Enterprise portal has landing page so initially user has to be redirected to that page
      navigate.to(
        selectedPortal?.type === PortalTypes.Enterprise
          ? ROUTES.HOME
          : redirectTo
      )
    }
  }, [location.pathname, allowedLinks])

  return (
    <div className={styles.wrapper}>
      <Navigation />

      <Sentry.ErrorBoundary
        key={path}
        fallback={<ErrorTemplate type={ErrorType.Generic} />}
      >
        <Outlet />
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default MainTemplate
