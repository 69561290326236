import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import * as yup from 'yup'

import { Col, Input, ReadOnlyFormValue, Row, Text } from '@/components/atoms'
import { EditUserSchema } from '@/features/user-management/utils'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'

interface IProps {
  editMode: boolean
}

const ProfileDetailsFormSection: FC<IProps> = (props) => {
  const { editMode } = props

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext<yup.InferType<typeof EditUserSchema>>()

  return (
    <Col gap={8} items="stretch">
      {!editMode && (
        <Text
          type={TextTypes.TEXT_MD}
          color={Color.gray700}
          className="tw-mb-8"
        >
          Enter the email address of the user you want to invite. Each invite is
          specific to an email address and will expire after <b>7 days</b>.
        </Text>
      )}

      <Row gap={8}>
        <Input
          {...register('first_name')}
          fullWidth
          required
          label="First Name"
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
        />
        <Input
          {...register('last_name')}
          fullWidth
          required
          label="Last Name"
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
        />
      </Row>

      {editMode ? (
        <ReadOnlyFormValue
          required
          biggerGap
          title="Email"
          value={watch('email')}
          className="tw-mt-6"
        />
      ) : (
        <Input
          {...register('email')}
          required
          label="Email"
          type="email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      )}
    </Col>
  )
}

export default ProfileDetailsFormSection
