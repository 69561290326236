import CloseIcon from '@mui/icons-material/Close'
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent
} from '@mui/material'
import React, { FC, useCallback } from 'react'

import { Row, Text } from '@/components/atoms'
import classes from '@/components/atoms/Input/classes'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'
import { ISelectOption } from '@/types/interfaces/ui'

import styles from './Select.module.scss'

interface IProps {
  value?: string | string[] | undefined
  multiple?: boolean
  options: ISelectOption[]
  onChange: (value: string | string[] | undefined) => void
  disabled?: boolean
  placeholder?: string
  required?: boolean
  helperText?: string
  label?: string
  error?: boolean
  fullWidth?: boolean
}

const CustomSelect: FC<IProps> = (props) => {
  const {
    label,
    options,
    value,
    multiple,
    onChange,
    disabled,
    placeholder,
    required,
    helperText,
    error,
    fullWidth = true
  } = props

  const { inputClasses, inputLabelClasses, helperTextClasses } = classes

  const showCloseBtn = !disabled && (multiple ? !!value?.length : !!value)

  const renderValue = useCallback((selected: string | string[] | undefined) => {
    const getValueLabel = (id: string) => {
      const item = options.find((i) => i.id === id)

      return item?.label || id
    }

    if (multiple) {
      return (
        <Row items="center" gap={8} className="tw-flex-wrap">
          {(selected as string[]).map((v: string) => (
            <div key={v} className={styles.chip}>
              <Text
                type={TextTypes.TEXT_XS}
                weight={FontWeight.SEMIBOLD}
                color={Color.gray700}
              >
                {getValueLabel(v)}
              </Text>
            </div>
          ))}
        </Row>
      )
    }

    return selected ? getValueLabel(selected as string) : ''
  }, [])

  const handleChange = (e: SelectChangeEvent<any>) => {
    if (multiple) {
      onChange(
        typeof e.target.value === 'string'
          ? e.target.value.split(',')
          : e.target.value
      )
      return
    }

    onChange(e.target.value)
  }

  const clear = () => onChange(multiple ? [] : undefined)

  return (
    <FormControl fullWidth={fullWidth}>
      {label && (
        <InputLabel
          variant="standard"
          error={error}
          required={required}
          classes={inputLabelClasses}
        >
          {label}
        </InputLabel>
      )}
      <Select
        id="demo-multiple-chip"
        error={error}
        multiple={multiple}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        value={multiple ? value || [] : value || ''}
        input={<Input classes={inputClasses} />}
        onChange={handleChange}
        renderValue={renderValue}
        endAdornment={
          showCloseBtn && (
            <div className={styles.clearButton} onClick={clear}>
              <CloseIcon />
            </div>
          )
        }
      >
        {options.length ? (
          options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              disabled={option.disabled}
              className={styles.menuItem}
              classes={{ selected: styles.selected }}
            >
              {option.hint ? `${option.label} (${option.hint})` : option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled value="" className={styles.menuItem}>
            No available options
          </MenuItem>
        )}
      </Select>

      {helperText && (
        <FormHelperText
          error={error}
          variant="standard"
          classes={helperTextClasses}
        >
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default CustomSelect
